.simple-selector {
  display: flex;
  margin-left: -10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;

  &__link {
    margin-left: 10px;
    color: $accent-color;

    &.selected {
      color: $text-color;
    }
  }
}
