.trips {
  @include responsive(0);

  .vertical-centered-box {
    background: none;
  }
}

.trips__loading {
  display: block;
  margin: 0 auto;
}

.trips__content-wrapper {
  padding-top: 20px;
}

.trips__error {
  padding: 20px;
  color: $red-color;
}

.trips__wrap {
  padding: 20px 0;
  background: $gray-color-light;
}

.trips__new-report-link {
  display: block;
  margin: 0 0 20px 20px;
}

.trips__mini-header {
  padding: 5px $base-margin;
  font-weight: bold;
}

.trips__status {
  padding: 25px 0;
  color: $gray-color-text;
  font-size: 14px;
}

.trips__status .steps {
  font-size: 12px;
}

.trips__status .steps + .steps {
  margin-top: 8px;
}

.trips__status .steps.high {
  color: #fff;
  font-weight: bold;
}

.trips__no-item {
  margin: 0 auto;
  text-align: center;
}

.trips__log-button {
  width: 100%;
  display: block;
  padding: 10px 20px;
  background: #fff 95% 50% / 10px auto no-repeat;
  border: solid 1px $gray-color-border;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 20px;
}

.trips__log-button.open {
  background-image: url('/images/down_chevron.png');
}

.trips__log-button.close {
  background-image: url('/images/up_chevron.png');
}

.trips__log-list {
  display: flex;
  @include flex-wrap(wrap);
  margin: 0 20px;
}

.trips__list-wrapper {
  margin: 0 20px;
}

.trips__list-wrapper:last-child > .trips__card-wrapper {
  margin-top: 30px;
}

$trips__radius-small: 5px;

.trips .content-body {
  background: $gray-color-light;
}

.trips__card-title {
  border-bottom: solid 1px $gray-color-light;
  padding: 5px;
}

.trips__card-content {
  padding: 10px;

  .places {
    color: $text-color;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .trips_totalprice {
    color: $text-color;
    font-weight: 600;
    margin-top: 5px;
  }
}

.trips__card-content-in {
  display: flex;
}

.trips__table {
  margin: 0;

  table,
  tbody,
  th,
  td {
    border: none;
  }

  td {
    padding: 0 0 5px;
    vertical-align: top;
  }

  .trips__table-data {
    width: 100px;
  }

  .trips__table-colon {
    width: 15px;
  }

  .trips__transit-wrapper {
    margin-bottom: 7px;
  }
}

.trips__approval-status {
  min-width: 200px;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .trips__list-wrapper {
    margin: 0;
  }

  .trips__card-title {
    font-size: 12px;
  }

  .trips__card-content-in {
    display: block;
  }

  .trips__approval-status {
    font-size: 11px;
  }
}
