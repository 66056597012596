.#{$prefixClass}-panel {
  &-input {
    margin: 0;
    padding: 0;
    width: 100%;
    cursor: auto;
    line-height: 1.5;
    outline: 0;
    border: 1px solid transparent;

    &-wrap {
      box-sizing: border-box;
      position: relative;
      padding: 6px;
      border-bottom: 1px solid #e9e9e9;
    }

    &-invalid {
      border-color: red;
    }
  }

  &-clear-btn {
    position: absolute;
    right: 6px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 6px;
    margin: 0;
  }

  &-clear-btn-icon:after {
    content: 'x';
    font-size: 12px;
    font-style: normal;
    color: #aaa;
    display: inline-block;
    line-height: 1;
    width: 20px;
    transition: color 0.3s ease;
  }

  &-clear-btn-icon:hover:after {
    color: #666;
  }
}
