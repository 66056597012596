.flash-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

@mixin flash-message__in {
  display: flex;
  margin: 0 auto;
  max-width: 800px;
  padding: 5px 10px;
}

.flash-message__error {
  @include flash-message__in;
  background: $flash-error-bg-color;
  border-left: 1px solid $red-color;
  border-right: 1px solid $red-color;
  border-bottom: 1px solid $red-color;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.flash-message__close {
  margin-left: auto;
  font-size: 16px;
  cursor: pointer;
}
