.member-approver_area {
  position: relative;
}

.select-approvers__return {
  display: block;
  padding: 8px 10px;
  background: $gray-color-light;
  border-bottom: solid 1px $gray-color;
  font-size: 13px;
  cursor: pointer;
}

.select-approvers__wrapper {
  font-size: 13px;
  margin: 10px 0 5px;
  min-width: 300px;
  background-color: $white-color;
  border: solid 1px $gray-color-border;
}

.select-approvers__title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.select-approvers__sub-title {
  font-weight: bold;
  font-size: 13px;
  margin-top: 10px;
}

.select-approvers__selected-list {
  margin-bottom: 15px;
}

.select-approvers__selected-list__error-message {
  margin: 10px auto;
  color: $red-color;
}

.select-approvers__selected-list__message {
  color: $black-color;
  width: 97%;
  @include display(inline-block);
  padding: 5px;
}

.select-approvers__selected-list__items {
  margin-bottom: 10px;
}

.select-approvers__selected-list__item {
  display: flex;
  border: 2px solid $gray-color;
  cursor: pointer;
}

.select-approvers__selected-list__right {
  margin: auto;
  display: inline-flex;
  align-items: center;
  column-gap: 6px;
  padding: 0 8px;
  line-height: 0;
}

.select-approvers__selected-list__reset {
  width: 2em;
  padding: 2px;
}

.select-approvers__selected-list__item__index {
  margin: 20px 0 5px;
}

.select-approvers__selected-list__item__deselect {
  @include round-button;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 0;
}

.select-approvers__selected-list__button-area {
  text-align: center;
}

.select-approvers__selected-list__button {
  @include button-base;
  display: inline-block;
  padding: 5px 10px;
  width: 130px;
  margin: 0 10px;
  font-weight: normal;
  text-align: center;
}

.approver-members-list {
  margin: 0;
  position: absolute;
  width: 100%;
  background-color: $white-color;

  input[type='search'] {
    margin: 0;
  }
}

.approver-members-list__item,
.approver-members-list__message {
  width: 100%;
  border: 2px solid $gray-color;
  margin: 0;
  cursor: pointer;

  &.selected,
  &.disabled {
    background: $gray-color;
    cursor: default;
  }
}

.approver-members-list__item + .members-list__item {
  border-top: 0;
}

.approver-members-list__item__info,
.approver-members-list__message__info {
  @include display(inline-block);
  @include flex-wrap(wrap);
  width: 97%;
  padding: 5px;
}

.approver-members-list__item-part {
  margin-right: 5px;
  @include display(inline-block);
}

.approver-members-list__item__name,
.approver-members-list__message__name,
.members-list__item__email {
  margin-right: 10px;
  display: inline-block;
}

.approver-members-list__item__email,
.members-list__item__department {
  font-size: 10px;
  color: gray-color;
}

.approver-members-list__item__selected-button {
  @include round-button-hilighted;
}

.approver-members-list__item__button {
  @include round-button;
}

.approver-members-list__item__selected-button,
.approver-members-list__item__button {
  margin-left: auto;
  margin-right: 0;
  width: 87px;
  padding: 5px;
}
