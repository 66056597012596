.popup-list-selector {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;

  @mixin button {
    display: flex;
    @include align-items(center);
    border: 1px solid $accent-color;
    padding: 3px 8px;
    border-radius: 11px;
    font-size: 11px;
    max-width: max-content;
    font-weight: bold;
    cursor: pointer;
  }

  &__button-show {
    @include button;
    color: white;
    background: $accent-color;

    &::after {
      content: 'x';
      position: absolute;
      top: 6px;
      right: 8px;
      $size: 12px;
      @include calc(border-radius, $size / 2);
      @include calc(font-size, $size - 1);
      background: white;
      line-height: 10px;
      color: $accent-color;
      width: $size;
      height: $size;
      text-align: center;
    }
  }

  &__button-hidden {
    @include button;
    color: $accent-color;
    background: white;

    &::after {
      content: '▼';
      position: absolute;
      right: 8px;
    }
  }

  &__select__button-hidden {
    @include button;
    color: $accent-color;
    background: #e0d7c1;

    &::after {
      content: '▼';
      position: absolute;
      right: 8px;
    }
  }

  &__button-show,
  &__button-hidden {
    display: block;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 3px 20px 3px 8px;
  }

  &__popup {
    position: absolute;
    top: 30px;
    border: 2px solid $accent-color;
    background: white;
    z-index: 1;

    &__header {
      display: flex;
      @include align-items(center);
      padding: 3px 8px 5px;
      background: $accent-color;
      color: white;

      &__close-button {
        margin-left: auto;
        cursor: pointer;
      }
    }

    &__body {
      padding: 5px;
      color: $accent-color;
      width: max-content;
      max-height: 165px;
      overflow-x: auto;

      &__label {
        cursor: pointer;

        &--select-all {
          border-bottom: 1px solid #eee;
          padding-bottom: 3px;
          margin-bottom: 3px;
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5px;
      padding-bottom: 5px;
      border-top: 1px solid #eee;
    }
  }

  &__submit {
    padding: 4px 16px;
  }
}
