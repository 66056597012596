.help,
.help-general,
.help-transit,
.help-hotel,
.help-cancel,
.help-other {
  @include responsive(0);
}

.help-wrapper {
  padding: $base-margin;

  h1 {
    margin-bottom: 10px;
  }
}

.help_link {
  font-size: 12px;
  margin: 10px 0;
  display: block;
}

.help_link-category {
  font-size: 14px;
  font-weight: bold;
}

.help_category-wrapper {
  padding: 10px 0;
  border-bottom: 1px solid $gray-color-light;
  margin-top: 30px;
}

.help-label {
  display: block;
  cursor: pointer;
  margin-top: 10px;
  border: 1px solid $base-background-color;
  font-weight: 600;
}

.help-label:hover {
  color: $text-color;
}

.help__answer-wrapper {
  background-color: #f4f8fa;
  font-size: 12px;
  margin-top: 5px;

  p {
    padding: 5px;
    border: 1px solid $gray-color-border;
  }
}

.help__subtitle {
  margin-top: 20px;
  font-size: 16px;
  color: $gray-color-text;
  font-weight: 600;
}

.help__back {
  font-size: 11px;
}

.help__menu {
  margin-top: 20px;
}

.help_category {
  display: block;
  margin: 10px 0;
}
