.step-bar {
  display: flex;
}

$step-bar-spacing: 6px;

.step-bar__step {
  flex: 1 1 100%;
  font-size: 18px;
  position: relative;
  background: white;
  font-weight: bold;
  margin-right: $step-bar-spacing;
}

.step-bar__step.thin {
  font-size: 13px;
  background: $gray-color;
}

.step-bar__step-in {
  position: relative;
  border-color: white;
  padding: 10px;
}

.step-bar__step-in.thin {
  padding: 5px;
  border-color: $gray-color;
}

.step-bar__step.current,
.step-bar__step.thin.current {
  background: $accent-color;
  color: white;
}

.step-bar__step-in.current,
.step-bar__step-in.thin.current {
  border-color: $accent-color;
}

.step-bar__step:after {
  position: absolute;
  top: 0;
  left: 100%;
  content: '';
  width: 0;
  height: 0;
  border: 24px solid transparent;
  z-index: 1;
  border-right-width: 0;
  border-left-width: 20px;
  border-left-color: $gray-color;
  transform: translateX($step-bar-spacing);
}

.step-bar__step.thin:after {
  border: 15px solid transparent;
  border-left-width: 10px;
  border-left-color: white;
}

.step-bar__step-in:after {
  position: absolute;
  top: 0;
  left: 100%;
  content: '';
  width: 0;
  height: 0;
  border: 24px solid transparent;
  z-index: 2;
  border-right-width: 0;
  border-left-width: 20px;
  border-left-color: inherit;
}

.step-bar__step-in.thin:after {
  border-width: 15px;
  border-left-width: 10px;
}

.step-bar__step:last-child {
  margin-right: 0;

  &:after,
  .step-bar__step-in:after {
    border: 0;
  }
}
