.trips-detail {
  padding: 20px;
}

.trips_detail__card-name {
  margin-top: 10px;
}

.trips_detail__card-name,
.trips_detail__card-title {
  font-weight: bold;
}

.trips_detail__table {
  margin: 0;

  .trips_detail__table-colon {
    width: 15px;
  }

  table,
  tbody,
  th,
  td {
    border: none;
  }

  td {
    padding: 0 0 3px;
    vertical-align: top;
  }

  .trips_detail__table-data {
    width: 60px;
  }
}

.trips_detail__hotel-detail {
  font-size: 12px;
}

.trips_detail__table-fee {
  font-size: 10px;
  color: $text-color;
}

.trips-detail__transit-wrapper {
  margin-bottom: 7px;
}

.trips_detail__transit-count + .trips-detail__transit-wrapper {
  margin-top: 10px;
}

.trips_detail__transit-count {
  font-size: 11px;
}

.trips-detail_back-link {
  display: block;
  margin-top: 30px;
}
