.department-edit {
  display: flex;
  @include justify-content(space-between);

  &__section {
    width: 48%;
    padding: 5px;
  }

  &__input_area {
    margin-bottom: 10px;
  }

  &__input_label {
    font-size: 13px;
    font-weight: normal;
  }

  &__radio-value {
    width: 80px;

    > span {
      padding-left: 4px;
    }
  }

  &__add-stage-button {
    float: left;
    margin-left: 10px;
  }

  &__modal-buttons {
    display: flex;
    @include justify-content(flex-end);

    > input,
    > input:hover:hover {
      width: 100px;
      margin-left: 20px;
    }
  }

  &__modal-errors {
    margin: $base-margin;
  }

  &__aprover_group {
    width: 100%;
    overflow: auto;

    > select {
      margin-bottom: 10px;
    }
  }
}
