.#{$prefixClass}-time-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 10;
  position: absolute;
  outline: none;
}

.#{$prefixClass}-time-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}

.#{$prefixClass}-time-panel-body {
  padding: 9px 10px 10px;
}

.#{$prefixClass}-time-panel-title {
  width: 180px;
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  height: 30px;
  line-height: 22px;
  border-radius: 4px;
}

.#{$prefixClass}-time-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 255px;
  border-collapse: separate;
}

.#{$prefixClass}-time-panel-cell {
  text-align: center;
  height: 42px;
  vertical-align: middle;
}

.#{$prefixClass}-time-panel-time {
  line-height: 26px;
  display: block;
  border-radius: 4px;
  width: 26px;
  margin: 0 auto;

  &:hover {
    background: #ebfaff;
    cursor: pointer;
  }
}

.#{$prefixClass}-time-panel-selected-cell .#{$prefixClass}-time-panel-time {
  background: $accent-color;
  color: #fff;

  &:hover {
    background: $accent-color;
    color: #fff;
  }
}
