.search {
  &__block {
    margin-bottom: 10px;

    &__header {
      display: flex;

      &__left {
        &__icon {
          border-radius: 9px;
          background: $accent-color;
          color: white;
          width: 18px;
          text-align: center;
          font-size: 12px;
        }
      }

      &__center {
        @include flex-grow(99999);
        margin: 3px auto 0 7px;
        font-size: 13px;

        &__title.detail {
          margin-left: 0;
        }
      }

      &__center.detail {
        margin-left: 0;
      }

      &__right {
        &__close {
          cursor: pointer;
        }
      }
    }

    &__body {
      margin-top: -10px;

      &__fields-wrapper {
        display: flex;
        @include flex-wrap(wrap);
        margin-left: -10px;

        @mixin field {
          @include flex-grow(99999);
          @include flex-basis(230px);
          background: white;
          border: 2px solid $gray-color-border;
          padding: 3px 5px;
          margin-top: 5px;
          margin-left: 10px;

          &__label {
            color: $accent-color;
            font-weight: bold;
            font-size: 12px;
          }

          &__input-address {
            color: $gray-color-text;
            font-weight: bold;
            font-size: 11px;
          }

          &__input-area {
            display: flex;
            @include align-items(flex-end);
            @include flex-wrap(wrap);

            &__stay-days {
              font-size: 12px;
              font-weight: bold;
              color: $gray-color-text;
            }

            &.nowrap {
              @include flex-wrap(nowrap);
            }

            &__detail-select-wrapper {
              @include flex-basis(105px);
            }
          }
        }

        &__simple-field {
          @include field;
        }

        &__field {
          @include field;

          &.disabled {
            background: $gray-color-light;
          }
        }
      }
    }
  }
}

.search__section {
  display: flex;
  border: 1px solid $gray-color-border;
  background: white;
  padding: 10px;
}

.search__section + .search__section {
  margin-top: 5px;
}

.search__section-small + .search__section {
  margin-top: 5px;
}

.search__section-small {
  display: flex;
  border: 1px solid $gray-color-border;
  background: white;
  padding: 10px;
  margin-top: 5px;
}

@mixin search-label {
  color: $gray-color-text;
  font-weight: bold;
}

$label-width: 55px;

.search__left-label,
.search__left-label-small {
  @include search-label;
  width: $label-width;
  line-height: 20px;
}

.search__left-label.error {
  color: $red-color;
}

@mixin search__input {
  @include placeholder {
    color: $gray-color-text;
  }

  border: 0;
  padding: 0 15px 0 0;
  margin-bottom: 0;
  box-shadow: none;
  line-height: 1;
  height: 20px;
  background: white;
  cursor: pointer;
}

.search__input-area {
  @include calc(width, '100% - #{$label-width}');
  display: flex;
  @include flex-wrap(wrap);
  position: relative;
}

textarea.search__input-area {
  margin-bottom: 0;
}

.search__input-area.search__input-airport {
  background: none;
  padding: 0;

  .search__select-wrapper-go {
    @include flex-grow(1);

    select.search__input {
      height: 40px;
      min-width: 80px;
      width: 100%;
      padding-left: 5px;
      border-radius: 5px;
    }
  }
}

.search__input-area.search__input-airport.search__air-wrapper-go {
  margin-right: -5px;
}

.search__input-area.search__input-airport.search__air-wrapper {
  @include display(block);
}

input.search__input {
  @include search__input;
}

input.search__input[type='text'] {
  padding-top: 2px;
}

select.search__input {
  @include search__input;
  @include appearance(none);
}

.select-pc .search__input {
  padding: 0 5px 0 0;
}

.pc .search__input-current-place {
  width: 70px;
  margin-top: 3px;
  text-align: center;
}

.sp .search__input-current-place {
  width: 55px;
  margin-top: 1px;
  font-size: 13px;
  text-align: center;
}

.select-pc .search__input-current-place {
  width: 55px;
  margin-top: 1px;
  font-size: 12px;
}

.search__select-wrapper-go:before {
  content: '';
  position: absolute;
  top: 44%;
  right: 5px;
  display: block;
  border: 5px solid transparent;
  border-top: 7px solid;
  pointer-events: none;
}

.search__input-hotel {
  width: 90px;
}

.search__select-wrapper-go {
  margin-right: 5px;
  position: relative;
  display: block;
}

.search__input-checkbox-go {
  display: flex;
  margin-right: 10px;
  cursor: pointer;
  height: 30px;
  line-height: 30px;

  input.search__input {
    height: 26px;
  }
}

.search__input-checkbox {
  display: flex;
  margin-right: 10px;
  cursor: pointer;
}

.search__input-checkbox-label {
  font-weight: normal;
}

.search__button {
  margin: 0 auto;
  padding: $base-margin;
}

.search__button input {
  @include big-button;
}

.search__error {
  padding-bottom: 10px;
}

.auto-completable-input {
  position: relative;
  background: #ffffff;
}

.pc .auto-completable-input {
  width: 340px;
}

.sp .auto-completable-input {
  width: 168px;
}

.select-pc .auto-completable-input {
  width: 135px;
}

.search__suggest-container {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 20;
  position: absolute;
  background: #ffffff;
}

.home .pc .search__suggest-container {
  top: 31px;
  width: 340px;
}

.sp .search__suggest-container {
  top: 22px;
  width: 168px;
}

.search__suggest-content:hover + .history {
  color: #7d7d7d;
}

.home .pc .search__suggest-wrapper {
  padding: 5px 7px;
}

.sp .search__suggest-wrapper {
  padding: 3px 5px;
}

.select-pc .search__suggest-wrapper {
  padding: 3px 4px;
}

.search__suggest-wrapper.highlight {
  background-color: #b8b7b7;
}

.search__suggest-icon {
  display: inline-block;
  margin: 2px 10px 0 0;
}

.search__suggest-text {
  vertical-align: top;
}

.search__suggest-subtext {
  margin-left: 7px;
  vertical-align: 2px;
  font-size: 12px;
  color: #aaa;
}

.search-loader-line-mask {
  @include transform-origin(12.5px);
  @include animation(rotate 1.2s infinite linear);
  width: 12.5px;
  height: 25px;
  margin: -3px 10px 0 40px;
  margin-left: 40px;
  margin-top: -3px;
  overflow: hidden;
}

.search-loader-line {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: inset 0 1px 1px 3px #af985e;
}

.search__add-block {
  margin-right: 10px;
  background: none;
  padding: 0;
  color: #af985e;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #816e40;
    background: none;
  }
}

@include keyframes(rotate) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
