$companyColor: #af985e;
$gray: rgb(246, 246, 246);
$pcWindowSize: 1280px;
$spWindowSize: 768px;

.simulation_pc {
  min-width: $pcWindowSize;
  min-height: 100vh;

  .balloon {
    position: relative;
  }

  .balloon:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -50px;
    left: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-style: solid;
    border-width: 50px 50px 0;
    z-index: 2;
  }

  header {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 15px 100px;
    background-color: black;
    z-index: 3;

    .header_container {
      width: 1080px;
      margin: 0 auto;

      .logos {
        .logo-img2 {
          display: inline-block;
          height: 30px;
          width: 119px;
        }

        img.good_design_award {
          border-radius: 6px;
          background-color: white;
          margin-left: 20px;
          padding: 5px;
        }
      }

      .phone_icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 5px 0;
        border-radius: 22.5px;
        background-color: #af985e;
        text-align: center;
      }

      .tel {
        display: inline-block;
        margin: 0 20px 0 5px;

        .tel_number {
          font-size: 0.9em;
          font-weight: 600;
          letter-spacing: 1.3px;

          span {
            color: white;
          }
        }

        .note {
          color: white;
          font-size: 0.5em;
        }
      }

      .inquiries {
        align-items: center;

        .button.inquiry {
          align-items: center;
          border-radius: 8px;
          background-color: $companyColor;
          color: white;
          height: 30px;
          padding: 0 30px;
          font-weight: 600;
        }
      }
    }
  }

  section {
    width: 100%;

    .section_container {
      justify-content: space-between;
      width: 1080px;
      margin: 0 auto;
    }

    &.key_visual {
      background-color: white;
      margin-top: 60px;
      padding-top: 50px;

      .key_catch {
        margin: 20px 0;
        font-size: 40px;
        font-weight: 600;
        text-align-last: justify;
      }

      .sub_catch {
        font-size: 18px;
      }

      .functions {
        justify-content: space-between;
        align-items: center;

        .function {
          align-items: center;

          .content {
            font-size: 0.8em;
            font-weight: bold;

            .strong {
              color: $companyColor;
              font-size: 1.4em;
              font-weight: bold;
            }
          }
        }
      }

      .button.demo {
        justify-content: center;
        align-items: center;
        width: 250px;
        border: 3px solid $companyColor;
        border-radius: 6px;
        margin-top: 40px;
        padding: 10px 20px;
        font-weight: bold;
        letter-spacing: 2px;
      }

      .cell:last-child {
        margin-bottom: -5px;
      }
    }

    &.simulation {
      background-image: url('/images/bg_black.png');
      background-position: bottom;
      background-size: cover;
      padding: 150px 100px;

      .container {
        width: 100%;
        margin: 0 auto;

        .simulator {
          position: relative;
          margin: 0 auto;
          color: rgb(85, 85, 85);

          .head {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            background-color: rgb(233, 233, 233);
            padding: 30px 120px;
            font-size: 1.8em;
            font-weight: bold;

            .ver {
              font-size: 0.8em;
              font-style: italic;
            }

            .text {
              font-size: 0.8em;
            }

            &:before {
              content: '';
              display: block;
              position: absolute;
              width: 100px;
              height: 100px;
              background-image: url('/images/biz_lp/balloon01.png');
              background-repeat: no-repeat;
              background-size: contain;
              top: -50px;
              left: 10px;
            }
          }

          .content {
            height: 100%;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            background-color: white;

            .inputs {
              justify-content: space-between;
              align-items: flex-end;
              padding: 50px 80px;
              font-weight: bold;

              .input {
                width: 200px;

                .title {
                  margin-bottom: 10px;
                }

                .small {
                  font-size: 0.6em;
                }

                .content {
                  align-items: center;
                  position: relative;
                  left: -16px;

                  input {
                    display: inline-block;
                    width: 150px;
                    height: 50px;
                    border: none;
                    border-bottom: 1px solid $companyColor;
                    border-radius: 0;
                    box-shadow: none;
                    margin-bottom: 0;
                    color: $companyColor;
                    font-weight: bold;

                    &::placeholder {
                      color: rgba(177, 162, 124, 0.58);
                      font-weight: bold;
                    }
                  }

                  p {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 180px;
                    padding-left: 16px;
                    color: $companyColor;
                    z-index: 1;

                    .num,
                    .text,
                    .unit {
                      display: inline;
                    }

                    .num {
                      font-size: 1.4em;
                    }

                    .text {
                      font-size: 0.9em;
                    }

                    .unit {
                      font-size: 0.9em;
                    }
                  }

                  select {
                    position: relative;
                    right: -16px;
                    display: inline-block;
                    width: 180px;
                    height: 50px;
                    border: none;
                    border-radius: 0;
                    -webkit-appearance: none;
                    -webkit-border-radius: 0;
                    border-bottom: 1px solid $companyColor;
                    box-shadow: none;
                    background: none;
                    margin-bottom: 0;
                    color: rgba(255, 255, 255, 0);
                    font-size: 0.9em;
                    font-weight: bold;
                    z-index: 2;

                    &::-ms-expand {
                      display: none;
                    }

                    option {
                      color: black;
                    }
                  }

                  img {
                    transform: rotate(90deg);
                    z-index: 1;
                  }
                }
              }

              .button.calc {
                display: flex;
                align-items: center;
                height: 50px;
                border: 3px solid $companyColor;
                border-radius: 6px;
                padding: 0 19px;
              }
            }
          }
        }

        .result_container {
          border-radius: 6px;
          border-top: 1px dashed #cccccc;
          background-color: white;
          padding: 60px 80px;

          .row {
            justify-content: space-between;
            margin-bottom: 34px;

            &:first-child {
              margin-bottom: 60px;
            }

            .title {
              font-weight: bold;

              .main {
                font-size: 1.8em;
                letter-spacing: 0.3em;
              }

              .sub {
                letter-spacing: 0.1em;
              }
            }

            .toggle {
              align-items: center;
              position: relative;
              width: 150px;
              height: 40px;

              input[type='radio'] {
                display: none;
              }

              label {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 3px solid $companyColor;
                text-align: center;
                font-size: 1.2em;
                font-weight: bold;
                line-height: 36px;
              }

              #year {
                + label {
                  right: 50%;
                  border-right: none;
                  border-radius: 6px 0 0 6px;
                  background-color: white;
                  color: $companyColor;
                }

                &:checked + label {
                  background-color: $companyColor;
                  color: white;
                }
              }

              #month {
                + label {
                  left: 50%;
                  border-radius: 0 6px 6px 0;
                  border-left: none;
                  background-color: white;
                  color: $companyColor;
                }

                &:checked + label {
                  background-color: $companyColor;
                  color: white;
                }
              }
            }

            .result {
              flex-direction: column;
              justify-content: center;
              width: 250px;
              height: 250px;
              border: 10px double $companyColor;
              border-radius: 150px;
              color: $companyColor;
              font-weight: bold;
              text-align: center;

              .head {
                font-size: 1.8em;

                .caution {
                  font-size: 0.5em;
                }
              }

              .container {
                justify-content: center;
                align-items: baseline;

                .amount {
                  font-size: 4em;

                  .unit {
                    font-size: 1.2em;
                    font-weight: bold;
                  }
                }
              }
            }

            .detail {
              justify-content: space-between;
              align-items: center;
              height: 250px;
              font-weight: bold;
              letter-spacing: 2px;

              .content {
                width: 600px;
                height: 100%;
                border: 2px solid rgb(155, 155, 155);
                border-radius: 8px;
                box-shadow: 0 0 1px 0 rgb(155, 155, 155);
                padding: 34px;

                .title {
                  margin-bottom: 20px;
                  color: rgb(155, 155, 155);

                  .caution {
                    font-size: 0.6em;
                  }
                }

                .container {
                  justify-content: space-between;

                  .items {
                    flex-direction: column;
                    justify-content: space-around;

                    .name {
                      display: inline-block;
                      width: 100px;
                    }

                    .number {
                      color: $companyColor;
                      font-size: 1.5em;

                      .unit {
                        font-size: 0.6em;
                      }
                    }
                  }
                }
              }
            }

            .balloon_left {
              position: relative;

              &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: -55px;
                border: 30px solid transparent;
                border-right: 30px solid #fff;
                margin-top: -30px;
                z-index: 2;
              }

              &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: -56px;
                border: 28px solid transparent;
                border-right: 28px solid rgb(155, 155, 155);
                margin-top: -28px;
                z-index: 1;
              }
            }

            .inquiry {
              justify-content: center;
              align-items: center;
              width: 600px;
              background-color: #a88b3c;
              border-radius: 4px;
              box-shadow: 0 2px 0 #6f5819;
              margin: 0 auto;
              padding: 20px;
              color: white;
              font-size: 1.6em;
              font-weight: bold;
              text-align: center;

              .icon {
                width: 48px;
                height: 48px;
              }

              .text {
                height: 32px;
                line-height: 32px;
              }
            }

            .explanation {
              font-size: 0.8em;
              font-weight: bold;
              color: rgb(155, 155, 155);
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.spec {
      background-color: $gray;
      padding: 80px 100px;

      h2 {
        text-align: center;
        font-size: 2em;
      }

      .features {
        margin-top: 50px;

        .feature {
          justify-content: center;
          margin-top: 10px;

          img {
            width: 540px;
            height: 360px;
          }

          .text {
            width: 540px;
            padding-top: 50px;

            h2 {
              text-align: left;
              font-size: 2em;
            }

            span {
              font-weight: bold;
              line-height: 40px;
            }
          }
        }
      }

      &.balloon:after {
        border-color: $gray transparent transparent;
      }
    }

    &.user_voice {
      background-color: white;
      padding: 130px 100px 80px;
      color: rgb(85, 85, 85);

      h2 {
        text-align: center;
        font-size: 2em;
      }

      .container {
        justify-content: space-between;
        align-items: center;
      }

      .voice {
        border: 6px solid rgb(236, 236, 236);
        border-radius: 8px;
        text-align: center;

        .title {
          background-color: rgb(236, 236, 236);
          padding: 10px 0;
          font-size: 1.3em;
          font-weight: bold;
        }

        .content {
          background-color: white;
          padding: 20px;
          text-align: left;
          font-size: 1em;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              line-height: 2em;

              &:before {
                content: '✔';
                margin-right: 5px;
                color: $companyColor;
              }
            }
          }
        }
      }

      &.balloon:after {
        border-color: white transparent transparent;
      }
    }

    &.inquiry {
      background-color: rgb(245, 242, 234);
      padding: 130px 0 80px;

      h2 {
        text-align: center;
        font-size: 2em;
      }

      h3 {
        text-align: center;
        font-size: 1.3em;
      }

      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        text-align: center;

        .tel {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 360px;
          height: 86px;
          border: 3px solid $companyColor;
          border-radius: 4px;
          background-color: white;
          font-weight: bold;
          color: $companyColor;

          .tel_number {
            span {
              color: $companyColor;
              font-size: 1.5em;
              letter-spacing: 2px;
            }
          }

          .note {
            font-size: 0.8em;
          }
        }

        .inquiries {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 360px;
          height: 86px;
          cursor: pointer;
          border: 3px solid $companyColor;
          border-radius: 4px;
          background-color: white;
          margin-left: 30px;
          font-weight: bold;

          a.button.inquiry {
            font-size: 2em;
          }
        }
      }
    }
  }

  footer {
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: black;
    color: white;
    font-size: 0.8em;
    margin-top: auto;
    padding: 0 100px;

    a {
      color: white;
    }
  }

  .thanks {
    min-height: 100vh;
    margin: 0 100px -60px;
    padding: 110px 30px 0;
    background-color: white;

    h2 {
      font-size: 1.8em;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 768px) {
  .simulation_pc {
    display: none;
  }
}

.simulation_sp {
  display: flex;
  flex-direction: column;
  max-width: $spWindowSize;
  min-width: 375px;
  min-height: 100vh;
  font-size: 0.6em;

  header {
    justify-content: space-between;
    align-content: center;
    position: fixed;
    width: 100%;
    height: 40px;
    background-color: black;
    color: white;
    z-index: 2;

    .logos {
      margin: 10px;

      .logo-img2 {
        height: 20px;
        width: 80px;
      }
    }

    .tel {
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      border-left: 1px solid white;

      .tel_number {
        font-size: 1.2em;
        letter-spacing: 2.5px;

        a {
          color: white;
        }
      }

      .note {
        font-size: 0.8em;
        font-weight: bold;
      }
    }

    .inquiries {
      padding: 0 10px;
      background-color: $companyColor;
      border-left: 1px solid white;

      .button.inquiry {
        align-items: center;
        color: white;
      }
    }
  }

  section {
    &.key_visual {
      width: 100%;
      background-color: white;
      margin-top: 40px;
      padding: 30px 30px 0;
      color: black;
      font-size: 1.3em;
      font-weight: bold;

      .key_catch {
        font-size: 2em;
      }

      .functions {
        justify-content: space-between;
        align-items: center;
        max-width: 400px;
        margin-top: 30px;
        font-weight: bold;
        text-align: center;

        .function {
          .content {
            .title {
              font-size: 0.6em;
            }

            .strong {
              color: $companyColor;
            }
          }
        }
      }

      .button.demo {
        justify-content: center;
        width: 120px;
        margin-top: 20px;
        padding: 10px;
        border-radius: 4px;
        border: 2px solid $companyColor;
      }

      .img {
        justify-content: center;
        align-items: flex-end;
      }
    }

    &.simulation {
      background-image: url('/images/bg_black.png');
      padding: 50px 30px;

      .container {
        width: 100%;
        margin: 0 auto;

        .simulator {
          position: relative;
          margin: 0 auto;
          color: rgb(85, 85, 85);

          .head {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            background-color: rgb(233, 233, 233);
            padding: 10px;
            text-align: center;
            font-size: 1.4em;
            font-weight: bold;

            .ver {
              font-size: 0.6em;
              font-style: italic;
            }

            .text {
              font-size: 0.6em;
            }

            &:before {
              content: '';
              display: block;
              position: absolute;
              width: 50px;
              height: 50px;
              background-image: url('/images/biz_lp/balloon01.png');
              background-repeat: no-repeat;
              background-size: contain;
              top: -25px;
              left: 10px;
            }
          }

          .content {
            position: relative;
            height: 100%;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            background-color: white;

            .inputs {
              flex-direction: column;
              padding: 20px;

              .input {
                justify-content: space-between;
                align-items: center;

                .title {
                  .small {
                    font-size: 0.8em;
                  }
                }

                .content {
                  align-items: center;

                  p {
                    position: absolute;
                    right: -26px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 180px;
                    color: $companyColor;
                    font-weight: bold;
                    z-index: 1;

                    .num,
                    .text,
                    .unit {
                      display: inline;
                    }

                    .num {
                      font-size: 1.8em;
                    }

                    .text {
                      font-size: 1.2em;
                    }

                    .unit {
                      font-size: 1em;
                    }
                  }

                  .bg {
                    position: absolute;
                    right: -6px;
                    background-color: #f5f2ea;
                    border-radius: 4px;
                    width: 175px;
                    height: 45px;
                  }

                  select {
                    position: relative;
                    right: -32px;
                    display: inline-block;
                    width: 175px;
                    height: 45px;
                    border-radius: 4px;
                    -webkit-appearance: none;
                    -webkit-border-radius: 0;
                    border: none;
                    box-shadow: none;
                    background: none;
                    margin-bottom: 5px;
                    padding: 10px 15px;
                    color: rgba(0, 0, 0, 0);
                    font-weight: bold;
                    z-index: 2;
                  }

                  img {
                    margin-right: 10px;
                    transform: rotate(90deg);
                  }
                }
              }

              .button.calc {
                border: 2px solid $companyColor;
                border-radius: 4px;
                margin: 30px auto 0;
                padding: 10px 30px;
                font-weight: bold;
              }
            }
          }
        }

        .result_container {
          border-radius: 6px;
          border-top: 1px dashed #cccccc;
          background-color: white;
          padding: 20px 20px 17px;

          .row {
            justify-content: space-between;
            margin-bottom: 15px;

            .title {
              font-weight: bold;

              .main {
                font-size: 1.4em;
                letter-spacing: 0.1em;
              }

              .sub {
                letter-spacing: 0.1em;
              }
            }

            .toggle {
              align-items: center;
              position: relative;
              width: 100px;
              height: 40px;

              input[type='radio'] {
                display: none;
              }

              label {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 3px solid $companyColor;
                text-align: center;
                font-size: 1.2em;
                font-weight: bold;
                line-height: 36px;
              }

              #yearSp {
                + label {
                  right: 50%;
                  border-right: none;
                  border-radius: 6px 0 0 6px;
                  background-color: white;
                  color: $companyColor;
                }

                &:checked + label {
                  background-color: $companyColor;
                  color: white;
                }
              }

              #monthSp {
                + label {
                  left: 50%;
                  border-radius: 0 6px 6px 0;
                  border-left: none;
                  background-color: white;
                  color: $companyColor;
                }

                &:checked + label {
                  background-color: $companyColor;
                  color: white;
                }
              }
            }

            .result {
              flex-direction: column;
              justify-content: center;
              width: 200px;
              height: 200px;
              margin: 0 auto;
              border: 10px double $companyColor;
              border-radius: 100px;
              color: $companyColor;
              font-weight: bold;
              text-align: center;

              .head {
                font-size: 1.8em;

                .caution {
                  font-size: 0.5em;
                }
              }

              .container {
                justify-content: center;
                align-items: baseline;

                .amount {
                  font-size: 4em;

                  .unit {
                    font-size: 1.2em;
                    font-weight: bold;
                  }
                }
              }
            }

            .detail {
              justify-content: center;
              align-items: center;
              margin: 20px auto 15px;
              font-weight: bold;
              letter-spacing: 2px;

              .content {
                border: 2px solid rgb(155, 155, 155);
                border-radius: 8px;
                box-shadow: 0 0 1px 0 rgb(155, 155, 155);
                padding: 20px;

                .title {
                  color: rgb(155, 155, 155);

                  .caution {
                    font-size: 0.6em;
                  }
                }

                .items {
                  flex-direction: column;
                  justify-content: space-around;
                  height: 100%;

                  .name {
                    display: inline-block;
                    width: 70px;
                  }

                  .number {
                    color: $companyColor;
                    font-size: 1.5em;

                    .unit {
                      font-size: 0.6em;
                    }
                  }
                }
              }
            }

            .inquiry {
              justify-content: center;
              align-items: center;
              width: 250px;
              height: 45px;
              background-color: #a88b3c;
              border-radius: 4px;
              box-shadow: 0 2px 0 #6f5819;
              margin: 0 auto;
              padding: 20px;
              color: white;
              font-size: 1.6em;
              font-weight: bold;
              text-align: center;

              .icon {
                width: 32px;
                height: 32px;
              }

              .text {
                height: 32px;
                line-height: 32px;
              }
            }

            .explanation {
              font-size: 0.8em;
              font-weight: bold;
              color: rgb(155, 155, 155);
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.spec {
      background-color: #f6f6f6;
      padding: 50px 30px;

      h2 {
        font-size: 1.6em;
        text-align: center;
      }

      .features {
        margin-top: 30px;

        .feature {
          text-align: center;

          .text {
            text-align: left;

            h2 {
              text-align: left;
            }
          }

          img {
            width: 100%;
            max-width: 500px;
            padding-top: 20px;
            padding-left: 40px;
          }
        }
      }
    }

    &.user_voice {
      background-color: white;
      padding: 50px 30px;

      h2 {
        font-size: 1.6em;
        text-align: center;
      }

      .voice {
        border: 6px solid rgb(236, 236, 236);
        border-radius: 8px;
        margin-top: 20px;

        .title {
          background-color: rgb(236, 236, 236);
          padding: 5px 15px;
          font-size: 1.3em;
          font-weight: bold;
        }

        .content {
          background-color: white;
          padding: 15px;
          font-size: 1.2em;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              line-height: 2em;

              &:before {
                content: '✔';
                margin-right: 5px;
                color: $companyColor;
              }
            }
          }
        }
      }
    }

    &.inquiry {
      background-color: rgb(245, 242, 234);
      padding: 50px 30px;

      h2 {
        font-size: 1.4em;
        text-align: center;
      }

      h3 {
        text-align: center;
      }

      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        text-align: center;

        .tel {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 360px;
          height: 50px;
          border: 3px solid $companyColor;
          border-radius: 4px;
          background-color: white;
          font-weight: bold;
          color: $companyColor;

          .tel_number {
            a {
              color: $companyColor;
              font-size: 1.5em;
              letter-spacing: 2px;
            }
          }

          .note {
            font-size: 0.8em;
          }
        }

        .inquiries {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 360px;
          height: 50px;
          cursor: pointer;
          border: 3px solid $companyColor;
          border-radius: 4px;
          background-color: white;
          margin-left: 30px;
          font-weight: bold;

          a.button.inquiry {
            font-size: 1.6em;
          }
        }
      }
    }
  }

  .inquiryForm {
    width: 100%;
    height: 100%;
    background-color: white;
    padding-top: 60px;
    color: black;

    @media screen and (max-width: 768px) {
      min-height: 100vh;
      margin: 0 auto -40px;
    }

    .container {
      width: 520px;
      margin: 80px auto;

      h2 {
        font-size: 1.8em;
        font-weight: bold;
      }

      .explanation {
        margin-bottom: 20px;
        font-size: 1.8em;
      }

      form {
        flex-direction: column;
        justify-content: space-between;

        .inputs {
          background-color: white;
          border-radius: 8px;

          .input {
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid black;
            font-size: 2.2em;

            .required,
            .optional {
              width: 50px;
              border-radius: 4px;
              margin-left: 0;
              padding: 10px;
              color: white;
              text-align: center;
            }

            .required {
              background-color: rgb(211, 196, 159);
            }

            .optional {
              background-color: rgb(186, 186, 186);
            }

            .content {
              display: inline-block;
              width: 265px;
              padding: 5px;
              padding-top: 0;
              padding-left: 0;

              input,
              textarea {
                width: 100%;
                margin: 0 0 0 auto;
                padding: 0;
                border: none;
                box-shadow: none;

                &::-webkit-input-placeholder {
                  color: $gray-color-text;
                }
              }

              textarea {
                height: 50px;
                resize: none;
                margin-bottom: 3px;
              }
            }

            .input_detail {
              width: 265px;
              padding-left: 11px;
              padding-bottom: 7px;

              .title {
                justify-content: center;
                align-items: center;
                width: 245px;
                padding: 5px;
                padding-left: 0;
                color: $companyColor;
                font-weight: bold;
                font-size: 0.8em;
              }

              .content {
                display: inline-block;
                width: 265px;
                padding: 5px;
                padding-top: 0;
                padding-left: 0;

                input,
                textarea {
                  width: 100%;
                  margin: 0 0 0 auto;
                  padding: 0;
                  border: none;
                  box-shadow: none;

                  &::-webkit-input-placeholder {
                    color: $gray-color-text;
                  }
                }

                textarea {
                  height: 50px;
                  resize: none;
                  margin-bottom: 3px;
                }
              }
            }

            &:last-child {
              align-items: flex-start;
              border-bottom: none;

              .optional {
                margin-top: 7px;
              }

              .input_detail {
                .content {
                  display: inline-block;
                  width: 265px;
                  padding: 5px;
                  padding-top: 0;
                  padding-left: 0;
                }
              }

              .content {
                display: inline-block;
                width: 265px;
                padding-bottom: 22px;
                padding-top: 15px;
                padding-left: 11px;
              }
            }
          }
        }

        .caution {
          margin-top: 10px;
          font-size: 0.7em;
        }

        .button {
          width: 100%;
          border-radius: 8px;
          margin-top: 0;
          padding: 20px 0;
          text-align: center;
          font-size: 2.2em;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .container {
        width: auto;
        max-width: 520px;
        margin: 30px auto;
        padding: 0 30px;
        font-size: 0.8em;

        .h2 {
          font-size: 1.4em;
        }

        form {
          font-size: 0.8em;

          .inputs {
            .input {
              justify-content: flex-start;

              .title {
                width: 120px;
                padding-right: 0;
              }

              .content {
                width: 50%;
                min-width: 265px;
                margin-left: 0;
                padding: 20px 10px;
              }
            }
          }

          button {
            font-size: 1.8em;
          }
        }
      }
    }

    &.active {
      display: block;
    }
  }

  .thanks {
    max-width: 450px;
    min-height: 100vh;
    margin: 0 auto -50px;
    padding: 80px 30px 0;
    background-color: white;

    h2 {
      font-size: 1.4em;
      font-weight: bold;
    }
  }

  footer {
    flex-direction: column;
    justify-content: center;
    height: 40px;
    background-color: black;
    margin-top: auto;

    .container {
      justify-content: space-evenly;
      font-size: 0.8em;

      a {
        color: white;
      }
    }

    .copyright {
      width: 100%;
      margin: 0;
      text-align: center;
    }
  }
}

@media screen and (min-width: 769px) {
  .simulation_sp {
    display: none;
  }
}

.inquiryForm {
  width: 100%;
  height: 100%;
  background-color: white;
  padding-top: 60px;
  color: black;

  @media screen and (max-width: 768px) {
    min-height: 100vh;
    margin: 0 auto -40px;
  }

  .container {
    width: 520px;
    margin: 80px auto;

    h2 {
      font-size: 1.8em;
      font-weight: bold;
    }

    .explanation {
      margin-bottom: 20px;
    }

    form {
      flex-direction: column;
      justify-content: space-between;

      .inputs {
        background-color: white;
        border-radius: 8px;

        .input {
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid black;

          .required,
          .optional {
            width: 50px;
            border-radius: 4px;
            margin-left: 20px;
            padding: 5px;
            color: white;
            font-size: 0.8em;
            text-align: center;
          }

          .required {
            background-color: rgb(211, 196, 159);
          }

          .optional {
            background-color: rgb(186, 186, 186);
          }

          .title {
            justify-content: center;
            align-items: center;
            width: 150px;
            padding: 20px;
            padding-left: 10px;
            color: $companyColor;
            font-weight: bold;
          }

          .content {
            display: inline-block;
            width: 350px;
            padding: 20px;

            input,
            textarea {
              width: 100%;
              margin: 0 0 0 auto;
              padding: 0;
              border: none;
              box-shadow: none;

              &::placeholder {
                color: rgb(204, 204, 204);
              }
            }

            textarea {
              height: 50px;
              resize: none;
            }
          }

          &:last-child {
            align-items: flex-start;
            border-bottom: none;

            .optional {
              margin-top: 20px;
            }
          }
        }
      }

      .caution {
        margin-top: 10px;
        font-size: 0.7em;
      }

      .button {
        width: 100%;
        border-radius: 8px;
        margin-top: 20px;
        padding: 20px 0;
        text-align: center;
        font-size: 1.2em;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      width: auto;
      max-width: 520px;
      margin: 30px auto;
      padding: 0 30px;
      font-size: 0.8em;

      .h2 {
        font-size: 1.4em;
      }

      form {
        font-size: 0.8em;

        .inputs {
          .input {
            justify-content: flex-start;

            .title {
              width: 120px;
              padding-right: 0;
            }

            .content {
              width: 50%;
              min-width: 200px;
              margin-left: auto;
              padding: 20px 10px;
            }
          }
        }

        button {
          font-size: 1.8em;
        }
      }
    }
  }

  &.active {
    display: block;
  }
}

.flex {
  display: flex;
}

.left {
  margin-right: auto;
}

.right {
  margin-left: auto;
}

.company_color {
  color: $companyColor;
}
