.cssloader,
.cssloader:before,
.cssloader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: cssload 1.8s infinite ease-in-out;
  animation: cssload 1.8s infinite ease-in-out;
}
.cssloader {
  color: #e2dac5;
  font-size: 10px;
  margin: 1px auto;
  padding: 0;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  top: -1em;
}
.cssloader:before,
.cssloader:after {
  content: '';
  position: absolute;
  top: 0;
}
.cssloader:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.cssloader:after {
  left: 2.5em;
}
@-webkit-keyframes cssload {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -0.8em;
  }
  40% {
    box-shadow: 0 1.5em 0 0;
  }
}
@keyframes cssload {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -0.8em;
  }
  40% {
    box-shadow: 0 1.5em 0 0;
  }
}
