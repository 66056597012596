.vertical-centered-box {
  display: flex;
  @include flex-direction(column);
  @include align-items(center);
  @include flex-grow(9999);
}

@include keyframes(rotate) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
