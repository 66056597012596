.hotel-pc__wrapper {
  @include calc(min-height, '100vh - #{$header-height} - #{$footer-height} - #{$pc-base-margin} * 2');
  background: white;
  font-weight: bold;
}

.hotel-pc__loading {
  padding: $pc-base-margin 0 0;
  text-align: center;
}

.hotel-pc__top-area {
  display: flex;
  @include align-items(center);
  border-bottom: 1px solid $gray-color-border;
  padding: 12px 15px;
}

.hotel-pc__title {
  font-size: 18px;
}

.hotel-pc__address {
  font-size: 12px;
}

.hotel-pc__soldout-message {
  margin-left: auto;
}

.hotel-pc__button-area {
  margin-left: auto;
}

.hotel-pc__button {
  @include round-button;
}

.hotel-pc__body-area {
  padding: $pc-base-margin;
  width: 100%;
}

$hotel-pc__thumb-width: 36px;
$hotel-pc__main-image-width: $hotel-pc__thumb-width * 13;
$hotel-pc__image-area-border-width: 3px;
$hotel-pc__side-margin: 24px;

.hotel-pc__image-area {
  @include flex-grow(1);
  max-width: $hotel-pc__main-image-width + $hotel-pc__image-area-border-width * 2;
  border: $hotel-pc__image-area-border-width solid $black-color;
  border-radius: 3px;
}

.hotel-pc__main-image {
  max-width: $hotel-pc__main-image-width;
  width: 100%;
  height: 288px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.hotel-pc__thumbs {
  display: flex;
  @include flex-wrap(wrap);
  max-width: $hotel-pc__main-image-width;
  background: $gray-color-light;
}

.hotel-pc__thumb,
.hotel-pc__thumb-selected {
  width: $hotel-pc__thumb-width;
  height: $hotel-pc__thumb-width;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.hotel-pc__thumb {
  background-blend-mode: color;
  background-color: rgba(255, 255, 255, 0.7);
}

$hotel-pc__map-width: 452px;

.hotel-pc .map {
  max-width: $hotel-pc__map-width;
  width: 100%;
  height: 254px;
}

.hotel-pc__info-area {
  display: flex;
  @include align-items(center);
  padding: 12px;
  background: $gray-color-light;
  font-size: 12px;
  flex-wrap: wrap;
  justify-content: left;
}

.hotel-pc__price-area {
  background: white;
  padding: 7px 10px;
  margin-left: auto;
}

.hotel-pc__price {
  font-size: 14px;
  color: $accent-color;
}

.hotel-pc__price-area .key-value-table {
  font-size: 10px;
  margin-bottom: 5px;
}

.hotel-pc__price-area__soldout {
  margin: 25px;
}

.hotel-pc__lower {
  display: flex;
  margin-top: $pc-base-margin;
  font-size: 12px;
}

.hotel-pc__amenity-area {
  display: flex;
  @include calc(max-width, '#{$hotel-pc__main-image-width} + #{$hotel-pc__image-area-border-width} * 2');
  width: 100%;
}

.hotel-pc__amenities {
  max-width: 223px;
  width: 100%;
}

.hotel-pc__amenities + .hotel-pc__amenities {
  margin-left: auto;
}

.hotel-pc__amenities-title {
  margin-bottom: 0;
}

.hotel-pc__ul {
  list-style-type: disc;
  margin-left: 20px;
}

.hotel-pc__li {
  font-size: 10px;
  font-weight: normal;
  text-indent: -5px;
}

.hotel-pc__readmore {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.hotel-pc__readmore_text {
  color: #af985e;
}

.hotel-pc__toggle-link {
  cursor: pointer;
  font-size: 10px;
}

.hotel-pc__others-area {
  max-width: $hotel-pc__map-width;
  width: 100%;
  margin-left: auto;
}

.hotel-pc__detail-box + .hotel-pc__detail-box {
  margin-top: $pc-base-margin;
}

.hotel-pc__detail-box-title {
  margin-bottom: 0;
}

.hotel-pc__detail-small {
  font-size: 10px;
  font-weight: normal;

  b {
    font-size: 12px;
    margin-top: $pc-base-margin;
    display: block;
  }
}

.hotel-pc__map-area {
  max-width: $hotel-pc__map-width;
  width: 100%;
  height: 367px;
  margin-left: auto;
}
