.#{$prefixClass}-month-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 10;
  position: absolute;
  outline: none;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.#{$prefixClass}-month-panel-hidden {
  display: none;
}

.#{$prefixClass}-month-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 30px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;

  > a {
    font-weight: bold;
    display: inline-block;
    padding: 4px 5px;
    text-align: center;
    width: 30px;

    &:hover {
      cursor: pointer;
      color: #23c0fa;
    }
  }
}

.#{$prefixClass}-month-panel-prev-year-btn,
.#{$prefixClass}-month-panel-next-year-btn {
  position: absolute;
  top: 0;
}

.#{$prefixClass}-month-panel-next-year-btn {
  &:after {
    content: '»';
  }
}

.#{$prefixClass}-month-panel-prev-year-btn {
  user-select: none;
  left: 0;

  &:after {
    content: '«';
  }
}

.#{$prefixClass}-month-panel .#{$prefixClass}-month-panel-year-select {
  width: 180px;
}

.#{$prefixClass}-month-panel-year-select-arrow {
  display: none;
}

.#{$prefixClass}-month-panel-next-year-btn {
  user-select: none;
  right: 0;
}

.#{$prefixClass}-month-panel-body {
  flex: 1;
  padding: 9px 10px 10px;
}

.#{$prefixClass}-month-panel-footer {
  border-top: 1px solid #ccc;
  line-height: 38px;
}

.#{$prefixClass}-month-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-collapse: separate;
}

.#{$prefixClass}-month-panel-cell {
  text-align: center;

  .#{$prefixClass}-month-panel-month {
    display: block;
    width: 46px;
    margin: 0 auto;
    color: #666;
    border-radius: 4px;
    height: 36px;
    padding: 0;
    background: transparent;
    line-height: 36px;
    text-align: center;

    &:hover {
      background: #ebfaff;
      cursor: pointer;
    }
  }

  &-disabled {
    .#{$prefixClass}-month-panel-month {
      color: #bfbfbf;

      &:hover {
        background: white;
        cursor: not-allowed;
      }
    }
  }
}

.#{$prefixClass}-month-panel-selected-cell .#{$prefixClass}-month-panel-month {
  background: $accent-color;
  color: #fff;

  &:hover {
    background: $accent-color;
    color: #fff;
  }
}

.#{$prefixClass}-month-header-wrap {
  position: relative;
  height: 308px;
}
