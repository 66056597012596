@font-face {
  font-family: at-icon;
  src: url('/fonts/at-icon.eot');
  src: url('/fonts/at-icon.eot?#iefix') format('embedded-opentype'), url('/fonts/at-icon.woff2') format('woff2'),
    url('/fonts/at-icon.woff') format('woff'), url('/fonts/at-icon.ttf') format('truetype'),
    url('/fonts/at-icon.svg#at-icon') format('svg');
  font-style: normal;
  font-weight: 400;
}

.at-icon {
  display: inline-block;
  transform: translate(0, 0);
  text-rendering: auto;
  font: normal normal 400 14px/1 at-icon;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.at-icon-lg {
  vertical-align: -15%;
  line-height: 0.75em;
  font-size: 1.33333333em;
}

.at-icon-2x {
  font-size: 2em;
}

.at-icon-3x {
  font-size: 3em;
}

.at-icon-4x {
  font-size: 4em;
}

.at-icon-5x {
  font-size: 5em;
}

.at-icon-fw {
  width: 1.28571429em;
  text-align: center;
}

.at-icon-search::before {
  content: '\ea01';
}

.at-icon-list::before {
  content: '\ea02';
}

.at-icon-check::before {
  content: '\ea03';
}

.at-icon-graph::before {
  content: '\ea04';
}
