$prefixClass: rc-time-picker;

.#{$prefixClass} {
  display: inline-block;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

@import './index/Picker';
@import './index/Panel';
@import './index/Header';
@import './index/Select';
