.organization-setting {
  .organization__header {
    display: flex;
    @include align-items(center);
    justify-content: space-between;
  }

  .save-btn {
    padding: 6px 37px;
    outline: none;
  }

  h3,
  h4 {
    margin-bottom: 0;
  }

  h3 {
    border-left: 5px solid $base-color;
    padding: 4px 0 4px 10px;
  }

  h4 {
    display: flex;
    align-items: center;
    margin-left: 16px;

    > * {
      display: block;
    }
  }

  section {
    position: relative;
    margin-top: 16px;
    margin-bottom: 16px;

    &.section--with-border {
      border-bottom: 1px solid #ddd;

      .title-with-tooltip {
        display: flex;
        position: relative;
        z-index: 2;
        align-items: center;
        margin-bottom: 0.75em;

        h4 {
          margin-bottom: 0;
        }

        .tooltip {
          margin-left: 8px;

          &__content {
            width: 450px;
            top: 16px;
            left: 50%;
            transform: translate(5%, -60%);

            p:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  .loading {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 68vh;
    z-index: 1;

    .vertical-centered-box {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .notice-message {
    margin: 24px 12px;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    margin-left: 0;
    background-size: 20px 20px;

    &--common {
      background-image: url('/images/organization/setting/icons/common.png');
    }

    &--hotel {
      background-image: url('/images/organization/setting/icons/hotel.png');
    }
  }
}

.organization-setting-body {
  position: relative;
  height: calc(100vh - 50px - 46px - 40px - 122px);
  overflow-y: scroll;
}

$organization-setting-table-cell-width: 108px;

.organization-setting-table {
  table-layout: fixed;
  margin: 0 0 6px 30px;
  width: auto;

  td {
    border: none;
    width: $organization-setting-table-cell-width;
    padding: 10px;
    vertical-align: text-top;

    &:first-child {
      width: 150px;
      position: relative;
      z-index: 0;

      label {
        display: inline-block;

        &::before {
          content: '・';
          display: inline-block;
        }
      }

      &.prefecture {
        padding-left: 20px;
      }
    }

    &.select-cell {
      width: $organization-setting-table-cell-width * 2;
    }

    .form-checkboxs {
      .form-checkbox:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    .form-checkbox {
      display: flex;
      align-items: center;

      input[type='checkbox'] {
        margin: 0 4px 0 0;
      }
    }

    .add-carrier-button {
      padding: 0.5em 1em;
      color: $accent-color;
      border-radius: 6px;
      border: 1px solid $accent-color;
      background-color: transparent;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }

    .remove-flight-query-setting-button {
      @include normal-button($red-color);
    }
  }

  .select-wrapper {
    position: relative;

    &::after {
      content: '▼';
      position: absolute;
      top: 6px;
      right: 0;
      color: $base-color;
      font-size: 10px;
    }
  }

  input,
  select {
    margin-bottom: 0;
  }

  select {
    padding: 4px 16px 4px 2px;
    outline: none;
    background: none;
    border: none;
    border-bottom: 1px solid #333;
    border-radius: 0;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .grade-box {
    border: 1px solid #333;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    line-height: 1;

    &--th {
      border-color: #ddd;
      background-color: $accent-color-light;
      color: #666;
      padding: 2px;
      font-weight: normal;
    }

    input {
      font-size: 12px !important;
    }
  }

  .add-grade-button {
    border-color: $base-color;
    cursor: pointer;
  }

  #add-grade-button {
    font-size: 12px;
    line-height: 1.4;
    color: $base-color;
  }

  .toggle-hotel-area-button {
    font-size: 10px;
    color: $base-color;
    cursor: pointer;
  }

  &--th {
    position: absolute;
    top: -10px;
    left: 0;
  }
}

.hotel-region-table.hidden,
.hotel-prefecture-tr.hidden {
  display: none;
}
