table.key-value-table {
  table-layout: auto;
  width: auto;
  margin: 0;
}

.key-value-table__label,
.key-value-table__separator,
.key-value-table__value {
  border-bottom: 0;
  padding: 0;
  vertical-align: top;
}

.key-value-table__label {
  p {
    display: inline-block;
  }
}

.key-value-table__value {
  p {
    display: inline-block;

    + p {
      margin-left: 10px;
    }
  }
}
