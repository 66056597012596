.inquiries__content-wrapper {
  background-color: $gray-color-light;
  padding: 20px;
  flex-grow: 9999;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

.inquiries__form-label {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin: 30px auto;
}

.inquiries__form {
  margin: 0 auto 30px;
  max-width: 700px;
  width: 100%;
  background-color: $white-color;
  padding: 40px;
  border: 1px solid $gray-color;
  border-radius: 5px;

  label {
    margin-bottom: 5px;
  }

  input[type='text'] {
    font-size: 18px;
  }

  span.red {
    color: $red-color;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 3px;
  }

  input[type='submit'] {
    font-size: 20px;
  }

  textarea {
    font-size: 18px;
  }
}

.inquiries__finish {
  margin: 0 15%;
  background-color: #ffffff;
  text-align: center;
}

.inquiries__finish-label {
  padding: 30px 0;
  font-size: 20px;
  font-weight: bold;
}

.inquiries__finish-text {
  padding-bottom: 30px;
}

.inquiries__error-messages {
  margin: 0 20px 10px;
}

.inquiries__error-message {
  margin-bottom: 0.75em;
  color: $red-color;
}

.inquiries__form-error {
  border-color: $red-color !important;
}
