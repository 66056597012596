$size: 22px;
$inner-size: 10px;

.form-elements-radio-field {
  &__radio {
    display: none !important;

    + label {
      padding-left: $size + 3px;
      position: relative;
      margin-right: 20px;
      color: $gray-color-text;
      line-height: $size;
    }

    + label::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: $size;
      height: $size;
      border: 2px solid $gray-color-text;
      border-radius: 50%;
    }

    &:checked + label {
      color: $accent-color;
    }

    &:checked + label::before {
      border-color: $accent-color;
    }

    &:checked + label::after {
      content: '';
      display: block;
      position: absolute;
      top: ($size - $inner-size) / 2;
      left: ($size - $inner-size) /2;
      width: $inner-size;
      height: $inner-size;
      background-color: $accent-color;
      border-radius: 50%;
    }
  }
}
