.select-search-area {
  table.key-value-table {
    font-size: 10px;
    color: $gray-color-text;
  }

  .key-value-table__label {
    font-weight: bold;
  }
}
