.organization__search-form {
  margin: 10px;
  border: 1px solid $gray-color-border;
  border-radius: 3px;
  background: $gray-color-light;
  padding: 10px;
  font-weight: bold;
}

.organization__search-item {
  display: flex;
}

.organization__search-item__label {
  line-height: 25px;
  margin-right: 5px;
  white-space: nowrap;
}

.organization__search-item__input {
  height: 25px;
  margin-right: 15px;
}

.organization__search-item__select {
  height: 25px;
  margin-right: 15px;
  margin-bottom: 0.75em;
}

.organization__search-item__comment {
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  font-weight: normal;
  margin-left: 15px;
}

.organization-trips {
  .ant-input {
    margin-bottom: 0;
  }

  input[type='submit'] {
    padding: 5px 10px;
  }

  .organization__table {
    text-align: right;
  }

  .organization__th,
  .organization__td {
    text-align: right;
    font-size: 12px;
    padding: 4px 8px;
  }
}

.organization-trips__date-area {
  display: flex;
  @include align-items(center);
  margin-bottom: 20px;

  select {
    margin-bottom: 0;
    height: 25px;
  }
}

.organization-trips__reload-button-area {
  margin-left: auto;
}

input.organization-trips__reload-button {
  &,
  &:hover,
  &:focus {
    height: 25px;
    width: 80px;
    padding: 0 10px;
  }
}

.organization-trips__bordered-area {
  &__empty {
    font-weight: normal;
    color: $gray-color-text;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 0.75em;

    h3 {
      margin-bottom: 0;
    }

    .tooltip {
      margin-left: 8px;

      &__content {
        width: 300px;
        top: 16px;
        left: 50%;
        transform: translateX(-50%);

        p:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.organization-trips__trend-area {
  text-align: center;
  overflow: auto;
}

.organization-trips__summary-area {
  margin-bottom: 10px;
}

.organization-trips__total-big {
  display: flex;
  font-size: 18px;
  margin-bottom: 10px;
}

.organization-trips__total-left {
  width: 40px;
}

.organization-trips__total-center {
  width: 18px;
  text-align: center;
}

.organization-trips__colored-text {
  color: $accent-color;
}

.organization-trips__tax {
  font-size: 13px;
}

.organization-trips__total-desc {
  font-size: 10px;
  font-weight: normal;
  margin: 3px 0 0 5px;
}

.organization-trips__csv-area {
  display: flex;
  @include justify-content(flex-end);
  @include align-items(center);
  margin: 10px 10px 30px;
}

.organization-trips__csv-label {
  font-weight: normal;
  margin-top: 1px;
}

.organization-trips__csv-select {
  margin: 0 10px 0 5px;
}

input.organization-trips__csv-button {
  &,
  &:hover,
  &:focus {
    width: 160px;
    padding: 5px;
  }
}

.organization-trips__project-select {
  margin: 0 10px 0 5px;
}

.organization-trips__chart-area-s {
  width: 380px;
  height: 300px;
}

.organization-trips__chart-area-m {
  width: 710px;
  height: 300px;
}

.organization-trips__chart-area-l {
  width: 1040px;
  height: 300px;
}

.organization-trips__chart-area-xl {
  width: 1360px;
  height: 300px;
}

.chart-labels {
  float: left;
  text-align: left;
}

.organization-trips-table {
  width: 100%;

  &__wrapper {
    padding: 0 10px;
  }

  &__tr {
    &.loading .vertical-centered-box {
      height: 58vh;
    }
  }

  &__tr-content {
    display: flex;
    @include align-items(center);
  }

  &__th {
    font-size: 12px;
    font-weight: bold;

    span {
      display: block;
      border-bottom: 2px solid #333;
      padding-bottom: 4px;
    }

    &:not(:last-child) span {
      margin-right: 12px;
    }
  }

  &__tbody {
    height: calc(100vh - 385px);
    overflow-y: scroll;

    .organization-trips-table__tr {
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }

  &__td {
    padding: 14px 0;
    font-weight: bold;

    small {
      display: block;
      font-weight: normal;
      font-size: 10px;
      margin-top: 4px;
    }

    .path {
      list-style-type: none;

      li {
        display: inline-block;

        &:not(:last-child) {
          &::after {
            display: inline-block;
            content: '→';
            margin: 0 2px;
          }
        }
      }
    }

    .arranges {
      list-style-type: none;

      li {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 6px;
        }
      }

      .icon {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0;
        background-size: 20px 20px;

        &--hotel {
          background-image: url('/images/organization/trips/icons/hotel.png');
        }

        &--air {
          background-image: url('/images/organization/trips/icons/air.png');
        }

        &--shinkansen {
          background-image: url('/images/organization/trips/icons/shinkansen.png');
        }

        &--option {
          background-image: url('/images/organization/trips/icons/option.png');
        }
      }
    }

    .cost::after {
      display: inline-block;
      content: '円';
    }

    .cost-check-mark {
      display: inline-block;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      padding: 6px;
      margin-right: 10px;
      vertical-align: middle;

      img {
        width: 15px;
        height: 15px;
      }
    }

    .btn {
      width: 60px;
    }

    &.travelers span + span {
      margin-left: 4px;
    }
  }

  &__th,
  &__td {
    &:nth-child(1) {
      width: 8%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 23%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 10%;
    }

    &:nth-child(6) {
      width: 21%;
    }

    &:nth-child(7) {
      width: 8%;
    }
  }

  .btn {
    display: inline-block;
    transition: all 0.3s;

    &--border {
      color: $base-color;
      border-radius: 6px;
      border: 1px solid $base-color;
      padding: 6px;
      background-color: transparent;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  // 汎用化しても良いかも
  [data-tooltip] {
    position: relative;
    cursor: pointer;

    &:hover {
      pointer-events: auto;

      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: attr(data-tooltip);
      position: absolute;
      bottom: 20px;
      left: 0;
      background: #333;
      border-radius: 3px;
      padding: 2px;
      color: $white-color;
      font-size: 10px;
      max-width: 100px;
      word-wrap: break-word;
      opacity: 0;
      transition: opacity 0.3s;
      pointer-events: none;
    }
  }
}
