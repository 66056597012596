$hotel-table__item-height: 130px;

.hotel-table__item {
  display: flex;
  min-height: $hotel-table__item-height;
  background: $gray-color-light;
  transition: background-color 0.5s;
}

.hotel-table__item.selected {
  background: $accent-color-light;
  transition: none;
}

.hotel-table__item + .hotel-table__item {
  margin-top: 5px;
}

.hotel-table__item.highlighted {
  background-color: $accent-color-light;
  transition: none;
}

.hotel-table__image-area {
  width: 216px;
  min-height: $hotel-table__item-height;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.hotel-table__info-area {
  padding: 7px 10px;
  font-size: 10px;
  @include flex-shrink(10000);
}

$hotel-table__title-index-width: 20px;

.hotel-table__title {
  font-weight: bold;
  color: $accent-color;
  font-size: 12px;
  text-indent: -$hotel-table__title-index-width;
  padding-left: $hotel-table__title-index-width;
}

.hotel-table__title-index {
  @include display(inline-block);
  width: $hotel-table__title-index-width;
  text-indent: 0;
}

.hotel-table__detail-area {
  margin-left: $hotel-table__title-index-width;
}

.hotel-table__address {
  font-weight: bold;
}

table.hotel-table__detail {
  table-layout: auto;
}

.hotel-table__detail-label,
.hotel-table__detail-separator,
.hotel-table__detail-value {
  border-bottom: 0;
  padding: 0;
}

.hotel-table__detail-label {
  width: 75px;
}

.hotel-table__price-area {
  display: flex;
  @include flex-direction(column);
  @include justify-content(center);
  border-left: 2px solid $gray-color-border;
  color: $accent-color;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  width: 228px;
  @include flex-grow(0);
}

.hotel-table__message.error {
  color: $red-color;
}

.hotel-table__message.success {
  color: $green-color;
}

.hotel-table__loading__message {
  margin-bottom: 10px;
}

.hotel-table__price {
  font-size: 15px;
}

.hotel-table__sub {
  font-size: 12px;
}

.hotel-table__buttons {
  display: flex;
  margin: 10px auto 0;
}

.hotel-table__detail-button {
  @include round-button-hilighted;
  margin-left: auto;
  margin-right: 0;
}

.hotel-table__select-button {
  @include round-button;
}

.hotel-table__selected-button {
  @include round-button-hilighted;
}

.hotel-table__detail-button,
.hotel-table__select-button,
.hotel-table__selected-button {
  width: 87px;
  padding: 5px;
  line-height: 12px;
  font-size: 12px;
}

.hotel-table__select-button,
.hotel-table__selected-button {
  margin-right: auto;
  margin-left: 10px;
}
