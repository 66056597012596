$size: 22px;
$inner-size: 14px;

.form-elements-checkbox-field {
  &__checkbox {
    display: none !important;
  }

  &__label {
    display: inline-block;
    padding-left: $size + 3px;
    position: relative;
    margin-right: 20px;
    color: $gray-color-text;
    line-height: $size;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: $size;
      height: $size;
      border: 2px solid $gray-color-text;
      border-radius: 4px;
    }
  }

  &__checkbox:checked + &__label {
    color: $accent-color;
  }

  &__checkbox:checked + &__label::before {
    border-color: $accent-color;
  }

  &__checkbox:checked + &__label::after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: $inner-size / 2;
    height: $inner-size;
    transform: rotate(40deg);
    border-bottom: 3px solid $accent-color;
    border-right: 3px solid $accent-color;
  }
}
