.select-travelers__return {
  display: block;
  padding: 8px 10px;
  background: $gray-color-light;
  border-bottom: solid 1px $gray-color;
  font-size: 13px;
  cursor: pointer;
}

.select-travelers__wrapper {
  font-size: 13px;
  margin-bottom: 5px;
  line-height: normal;
}

.select-travelers__title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.select-travelers__sub-title {
  font-weight: bold;
  font-size: 13px;
  margin-top: 10px;
}

.select-travelers__selected-list {
  margin-bottom: 15px;
}

.select-travelers__selected-list__error-message {
  margin: 10px auto;
  color: $red-color;
}

.select-travelers__selected-list__message {
  color: $black-color;
}

.select-travelers__selected-list__items {
  margin-bottom: 10px;
}

.select-travelers__selected-list__item {
  display: flex;
  padding: 5px 20px;
  border: 2px solid $gray-color;
  cursor: pointer;
}

.select-travelers__selected-list__right {
  margin-left: auto;
}

.select-travelers__selected-list__item__index {
  margin: 20px 0 5px;
}

.select-travelers__selected-list__item__deselect {
  @include round-button;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 0;
}

.select-travelers__selected-list__button-area {
  text-align: center;
}

.select-travelers__selected-list__button {
  @include button-base;
  display: inline-block;
  padding: 5px 10px;
  width: 130px;
  margin: 0 10px;
  font-weight: normal;
  text-align: center;
}

.members-list {
  margin: 0;

  input[type='search'] {
    margin: 0;
  }
}

.members-list__item {
  width: 100%;
  padding: 5px 20px;
  border: 2px solid $gray-color;
  margin: 0;
  cursor: pointer;

  &.selected,
  &.disabled {
    background: $gray-color;
    cursor: default;
  }
}

.members-list__item + .members-list__item {
  border-top: 0;
}

.members-list__item__info {
  display: flex;
  @include flex-wrap(wrap);
}

.members-list__item-part {
  margin-right: 5px;
}

.members-list__item__name,
.members-list__item__email {
  margin-right: 10px;
}

.members-list__item__email,
.members-list__item__department {
  font-size: 10px;
  color: gray-color;
}

.members-list__item__selected-button {
  @include round-button-hilighted;
}

.members-list__item__button {
  @include round-button;
}

.members-list__item__selected-button,
.members-list__item__button {
  margin-left: auto;
  margin-right: 0;
  width: 87px;
  padding: 5px;
}
