.#{$prefixClass}-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 12px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 5px #ccc;
    background-clip: padding-box;
    border: 1px solid #ccc;
    line-height: 1.5;
  }

  &-narrow {
    max-width: 113px;
  }
}
