.trip-modal {
  position: fixed;
  width: 350px;
}

.trip-modal__cancel-message {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.trip-modal__cancel-reason-hint {
  margin-left: 30px;
  margin-bottom: 10px;
}

.trip-modal__cancel-reason {
  width: 250px;
  height: 80px;
  margin-left: 30px;
  padding: 10px;
}

.trip-modal__cancel-reason-remark {
  margin: 10px 20px 20px 25px;
  font-size: 12px;

  &:before {
    margin-left: -1em;
    content: '※';
  }
}

.trip-modal__error {
  color: $red-color;
  margin: 20px;
}

.trip-modal__cancel-reason-buttons {
  text-align: center;
}

.trip-modal__back-button {
  margin-left: 20px;
}
