$home-main-height: 760px;
$home-search-area-height: 90px;
$home-pc-width: 1000px;

.home {
  .content-body {
    background: none;
  }
}

@mixin cover-bg($url, $height) {
  height: $height;
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  &:after {
    background: url($url) no-repeat center center;
    background-size: cover;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
}

//
// PC
//
.home-pc__main {
  @include cover-bg('/images/home/bg_01.png', $home-main-height);
}

.home-pc__main-in {
  width: $home-pc-width;
  margin: 0 auto;
}

.home-pc__header-button {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;

  img {
    margin-left: 30px;
  }
}

.home-pc__header-button .biz-link {
  text-decoration: underline;
  color: $white-color;
  margin: 5px 12px 0 0;
  font-weight: 500;
}

.biz-sp-link {
  margin: 18px auto 12px 17px;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  z-index: 10;
}

.home-pc__login {
  padding: 30px 5px 5px 0;
  text-align: right;
  width: 100%;
}

.home-pc__campaign {
  width: 90%;
  text-align: left;
  padding: 10px 0 0 5px;
  color: $base-background-color;
  text-decoration: underline;
}

.home-pc__tagline2 {
  margin: 20px 0 18px;
}

.home-pc__main-image {
  margin-left: auto;
}

.home-pc__form-area {
  .search__section {
    background: none;
    border: none;
    padding: 0;
    text-align: left;
    margin-bottom: 18px;
  }

  .search__section-small {
    background: none;
    border: none;
    padding: 0;
  }

  .search__left-label {
    color: white;
    width: 75px;
    line-height: 40px;
  }

  .search__left-label-small {
    color: white;
    width: 75px;
    line-height: 30px;
    text-align: left;
    margin-bottom: 5px;
  }

  .search__input {
    height: 30px;

    @include placeholder {
      color: $gray-color-text;
    }
  }

  .search__input-area {
    background: white;
    border-radius: 5px;
    padding: 5px;
  }

  input.search__input {
    padding-left: 10px;
  }

  .btn-go {
    &,
    &:hover,
    &:focus {
      background: url('/images/home/go.png') no-repeat;
      width: 286px;
      height: 54px;
    }
  }
}

.home-pc__section {
  padding-top: 100px;
  padding-bottom: 40px;
  text-align: center;
}

.home-pc__section-in {
  width: $home-pc-width;
  margin: 0 auto 20px;
}

.home-pc__about {
  background: white;
}

.home-pc__en-title {
  margin-bottom: 13px;
}

.home-pc__title {
  margin-bottom: 48px;
}

.home-pc__about-p {
  font-size: 30px;
  margin-bottom: 56px;
}

.home-pc__about-italic {
  font-style: italic;
  color: $base-color;
  font-weight: bold;
}

.home-pc__easy {
  background: white;
}

.home-pc__shadowed {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.home-pc__easy-popup {
  position: relative;
  text-align: right;
  top: -435px;
  left: 53px;
}

.home-pc__easy-point {
  margin-top: -84px;
  text-align: left;
  padding: 40px 50px;
  width: $home-pc-width;
  height: 150px;
  background: url('/images/home/point_bg.png') no-repeat;
  background-color: #fbf8e8;
}

.home-pc__feature-block {
  width: 265px;
  height: 272px;
  margin: 0 auto 33px;
}

.home-pc__howto {
  background: url('/images/home/howto_bg.png');

  .home-pc__section-in {
    width: 1050px;
  }
}

.home-pc__howto-flow {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px 10px;
  margin: 0 auto 12px;
  width: $home-pc-width;
}

.home-pc__howto-flow-box {
  width: 290px;
}

.home-pc__howto-flow > div {
  margin-right: auto;
  margin-left: auto;
}

@mixin howto-box {
  width: 290px;
  height: 80px;
  border-radius: 5px;
  padding-top: 20px;
  font-weight: bold;
}

.home-pc__howto-flow_on {
  @include howto-box;
  background: #af9960;
  color: white;
}

.home-pc__howto-flow_off {
  @include howto-box;
  background: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.home-pc__howto-step-num {
  color: #ddcea6;
}

.home-pc__howto-flow-arrow_off {
  display: none;
}

.home-pc__howto-flow-arrow {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.home-pc__howto-detail {
  height: 385px;
  background: url('/images/home/howto_mac.png');
  padding-left: 25px;
  text-align: left;
  color: white;
}

.home-pc__howto-detail-left {
  width: 420px;
}

.home-pc__howto-detail-title {
  margin-bottom: 30px;
  padding-top: 90px;
}

.home-pc__howto-detail-img {
  padding-top: 55px;
  padding-left: 76px;
}

.home-pc__voice-box {
  display: flex;
  width: 490px;
  background: white;
  padding: 30px 35px;
  margin-bottom: 20px;
}

.home-pc__voice-text {
  margin-left: 30px;
  text-align: left;
  width: 320px;
  padding-top: 7px;
}

.home-pc__vocie-user {
  margin-top: 20px;
  color: $gray-color-text;
}

.home-pc__qa {
  background: white;
  margin-right: auto;
  margin-left: auto;

  .home-pc__section-in {
    width: 800px;
  }
}

.home-pc__qa-text {
  padding-top: 4px;
  padding-left: 15px;
}

.home-pc__bottom {
  @include cover-bg('/images/home/bottom_bg.png', 290px);
}

.home-pc__bottom1 {
  padding-top: 60px;
  margin-bottom: 25px;
}

.home-pc__bottom2 {
  margin-bottom: 25px;
}

.home-pc__social {
  background: $black-color;
  padding-top: 40px;
  padding-bottom: 20px;
}

.home-pc__up-arrow {
  text-align: right;
}

.home-pc__footer {
  background: $black-color;
  border-top: 1px solid #434343;
  padding: 20px 0;
  @include calc(min-height, '100vh - #{$home-main-height}');
}

.home-pc__footer-link {
  font-size: 12px;
  color: $gray-color-text;
}

.home-pc__announcement-text {
  color: $white-color;
  margin-top: 12px;
  text-decoration: underline;
}

.home-pc__header-button > :first-child {
  margin-right: auto;
}

.home-sp__announcement-text {
  display: block;
  font-size: 12px;
  margin-top: 20px;
  text-decoration: underline;
}

//
// SP
//

@media screen and (max-width: $home-pc-width) {
  .home-sp__campaign {
    text-decoration: underline;
  }

  $home-main-height: 320px;

  .home__main {
    height: $home-main-height;
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .home__main:after {
    background: #111 url('/images/home_bg.png') no-repeat center center;
    background-size: cover;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  .home__tagline {
    padding-top: 80px;
    color: white;
    font-size: 22px;
    margin-bottom: 10px;
  }

  .home__copy,
  .home__copy-sp {
    color: white;
    font-size: 12px;
    font-weight: normal;
    padding-right: 20px;
    padding-left: 20px;
    line-height: 22px;
  }

  .home__copy-sp {
    margin-top: 10px;
  }

  input[type='submit'].home__search-button {
    @include button-base;
    width: 240px;
    padding: 13px 13px 13px 30px;
    background: url('/images/icon_search.png') 48px 11px / 14px 15px no-repeat;
    border-radius: 25px;
    font-size: 12px;
  }

  .home__section {
    padding: 30px 35px;

    p {
      font-size: 14px;
    }

    p + p {
      margin-top: 20px;
    }
  }

  #home__howto.home__section {
    padding: 30px 0;
  }
  #home__description,
  #home__howto,
  #home__reason {
    background: white;
  }

  #home__needs,
  #home__voices {
    background: $gray-color-light;
  }

  .home__common-title {
    text-align: center;
    color: $base-color;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .home__section .flex .box-icon {
    width: 50px;
  }

  .home__section .flex .box-text {
    @include flex-shrink(1);
  }

  $home-slide-width: 260px;
  $home-slide-margin: 8px;

  #home__howto .slide-wrapper {
    padding: 0 $home-slide-margin;
  }

  #home__howto .slide {
    width: $home-slide-width;
    border: solid 1px $gray-color;
  }

  #home__howto .slide .image-wrap {
    background-color: $gray-color;
  }

  #home__howto .slide .text-wrap {
    width: 100%;
    padding: 10px;
    border-top: solid 1px $gray-color;
  }

  #home__howto .slide .box-icon {
    padding: 12px 15px 0 5px;
  }

  #home__howto .slide .slide-title {
    font-size: 14px;
    margin-bottom: 5px;
  }

  #home__howto .slide .slide-text {
    font-size: 11px;
    line-height: 1.8;
  }

  #home__voices p {
    font-size: 12px;
    font-weight: bold;
  }

  #home__voices p .name {
    font-weight: normal;
  }

  #home__voices li + li {
    margin-top: 20px;
  }

  #home__voices .box-icon {
    margin-right: 8px;
  }

  #home__voices .box-icon img {
    height: auto;
  }

  #home__reason .reason {
    padding-left: 28px;
    background: url('/images/checkmark.png') 0 0 / 20px 17px no-repeat;
    font-size: 12px;
    font-weight: bold;
  }

  #home__reason .reason + .reason {
    margin-top: 5px;
  }

  #home__reason .reason .note {
    font-size: 11px;
    font-weight: normal;
  }

  .home__buttons {
    display: flex;
    position: absolute;
    bottom: 0;
    background: white;
    width: 100%;
    font-weight: bold;
  }

  .home__button {
    color: $base-color;
    padding-top: 11px;
    @include calc('width', '100% / 2');

    &:hover {
      color: $base-color;
    }
  }

  .home__history-button {
    border-right: 1px solid $base-color;
  }
}
