//
// organization
//
@mixin organization-width {
  max-width: 100%;
  min-width: $organization-min-width;
  width: 100%;
}

.organization__content-wrapper {
  @include flex-grow(9999);
  @include organization-width;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background: $gray-color;
}

.organization {
  .header-in {
    padding: 0 20px;
    @include organization-width;
  }
}

$organization-navi-width: 210px;
$organization-base-margin: 20px;

.organization__left {
  width: $organization-navi-width;
  background: #313541;
  padding: 10px 15px;
  margin: 0;
}

.organization__right {
  @include calc(width, '100% - #{$organization-navi-width}');
  overflow-y: scroll;
  background: white;
  margin: $organization-base-margin;
}

.organization {
  font-size: 13px;
}

.organization__header-home {
  margin: auto 10px;
  border: 1px solid $base-color;
  padding: 5px;
  border-radius: 5px;
  display: block;
  font-weight: 600;
}

.organization__header {
  border-bottom: 1px solid $gray-color-border;
  padding: 10px;
}

.organization__content-body {
  padding: 10px;
}

.organization__title {
  margin: 0;
}

.organization__notes {
  font-size: 12px;
  margin-bottom: 10px;
}

.organization__table {
  margin: 0;
  table-layout: auto;
}

.organization__th,
.organization__button-th {
  background: $gray-color-light;
  font-size: 10px;
  font-weight: normal;
  border-bottom: none;
  border-right: solid 1px white;
  padding: 4px;
}

.organization__button-th {
  width: 100px;
}

.organization__tr {
  height: 45px;
}

.organization__td {
  font-size: 12px;
  font-weight: bold;
  padding: 8px 4px;
}

.organization__td-disabled {
  font-size: 12px;
  font-weight: bold;
  padding: 8px 4px;
  background-color: gray;
}

.organization__td-left {
  margin-left: auto;
}

.organization__td .tooltip {
  margin-right: auto;
}

@mixin organization-button {
  &,
  &:hover,
  &:focus {
    display: block;
    width: 80px;
    margin: 2px auto;
    padding: 5px 0;
  }
}

input.organization__button {
  @include organization-button;
}

a.organization__chat {
  display: block;
  width: 50px;
}

input.organization__delete-button[type='submit'] {
  @include organization-button;

  &,
  &:hover,
  &:focus {
    background: $red-color;
  }
}

input.organization__order-button {
  margin-left: 20px;
}

input.organization__add-button {
  margin-top: 10px;

  &,
  &:hover,
  &:focus {
    width: 200px;
  }
}

.organization__buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.organization__add-approver-button-wrap {
  float: left;
  width: 90px;
  margin: 0 10px 0 auto;
  font-size: 10px;
}

.organization__approver_list {
  margin-left: 10px;
}

input.organization__setting__hotel-price-limit {
  width: 100px;
  margin-right: 10px;
}

.organization__setting-message {
  font-weight: bold;
  margin: 10px 0;

  &__success {
    color: $green-color;
  }

  &__error {
    color: $red-color;
  }
}

.organization__csv-sections {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.organization__csv-download {
  margin: 10px 0 0;

  > * {
    display: inline-block;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.organization__csv-format-table {
  .row-name {
    width: 40%;
  }

  .summary {
    width: 24%;
  }

  .example {
    width: 35%;
  }
}

.organization__csv-actions {
  text-align: center;

  a,
  button {
    margin: 0 10px;
  }
}
