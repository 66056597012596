.select-transport-box {
  @include select-item-box;

  &__content {
    display: flex;
  }

  &__footer {
    background: $gray-color-border;
    color: $gray-color-text;
    padding: 5px;

    &.selected {
      background: $accent-color;
      color: white;
    }
  }

  &__left {
    @include flex-shrink(10);

    &__segment {
      padding: 5px 0;
    }

    &__name {
      font-weight: bold;
    }

    &__image {
      height: 20px;
      margin-left: 5px;
    }

    &__boarding-arrival {
      margin-top: 10px;
      &:empty {
        margin-top: 0px;
      }
    }

    &__icon-body {
      display: flex;
      @include align-items(center);
      margin-top: 3px;

      &__text {
        margin-left: 5px;
      }
    }
  }

  &__right-wrapper {
    display: flex;
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    @include flex-shrink(1);
    @include align-items(center);
    margin-right: 3px;
    margin-left: auto;
    background-color: #f7f7f7;
    min-width: 160px;

    &.flight {
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(flex-end);
      padding: 10px 10px 10px 5px;
    }

    &.domestic {
      background-color: transparent;
    }
  }

  &__right-wrapper-shortdistance {
    display: flex;
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include flex-shrink(1);
    @include align-items(center);
    margin-right: 3px;
    margin-left: auto;
    min-width: 160px;
    flex-direction: column;

    &.flight {
      @include flex-direction(column);
      @include justify-content(center);
      @include align-items(flex-end);
      padding: 10px 10px 10px 5px;
    }
  }

  &__button_exist_leg {
    background-color: #fcfce4 !important;
    border: 2px solid #a9a9a9 !important;
  }

  &__button_exist_leg_shortdistance {
    display: flex;
    align-items: center;
    border: 1px solid #999999 !important;
    width: 100%;
    padding: 0.5rem;
    &__separate {
      background-color: #fffdf1 !important;
      border: 1px solid #999999 !important;
    }
  }

  &__button_exist_leg_station {
    font-weight: bold !important;
    vertical-align: middle !important;
  }

  &__right {
    display: flex;
    @include align-items(center);
    @include flex-shrink(0);

    border: 2px solid $accent-color;
    border-radius: 3px;
    padding: 2px;
    padding-left: 8px;
    margin: 2px;
    color: $accent-color;
    font-weight: bold;
    cursor: pointer;
    min-width: 87px;
    height: 45px;

    &.flight {
      min-width: 116px;
    }

    &.selected {
      background: $accent-color;
      color: white;
    }

    &.disabled {
      border-color: $gray-color-border;
      color: $gray-color-text;
      width: 100%;
    }

    &__body__select {
      font-size: 14px;
      margin: 0 auto;
      text-align: center;
    }

    &__icon {
      margin-left: 10px;
    }
  }

  &__right-deadline {
    padding-left: 10px;
  }
}
