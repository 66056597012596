.user-signin {
  background: url('/images/user_signin/bg.png') center / cover no-repeat;
  color: #fff;

  &__header {
    padding: 8px;

    .logo-img2 {
      height: 30px;
    }
  }

  &__body {
    display: flex;
    @include align-items(center);
    max-width: 350px;
    width: 100%;
    height: calc(100vh - 165px);
    margin: 0 auto;

    > * {
      width: 100%;
    }

    .logo-container {
      display: flex;
      @include align-items(center);
      @include justify-content(center);
      background-color: #000;
      height: 140px;
      width: 100%;

      .logo-img2 {
        height: 69px;
        width: 100%;
        background-position: center;
      }
    }

    .form-container {
      width: 100%;
      background-color: $gray-color-light;
      padding: 32px;
      text-align: right;

      .success,
      .error {
        text-align: center;
        font-size: 12px;
        margin-bottom: 8px;
      }

      form {
        margin-bottom: 8px;
      }

      .user-signin__section label {
        color: $base-color;
      }
    }

    input,
    button {
      width: 100%;
    }

    input::placeholder {
      font-size: 12px;
    }
  }

  &__inner {
    display: flex;
    @include justify-content(space-between);
    @include align-items(center);
    max-width: 960px;
    margin: 0 auto;
  }

  .text-link {
    display: inline-block;
    color: #fff;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  .btn-link {
    display: inline-block;
    color: #fff;
    padding: 8px 0;
    width: 120px;
    border: 1px solid #fff;
    border-radius: 4px;
    text-align: center;
    line-height: 1;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  .footer {
    background-color: rgba(#000, 0.3);
  }
}
