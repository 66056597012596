.cost-analysis {
  &__search {
    margin-bottom: 10px;

    &__form {
      display: flex;
      margin-bottom: 5px;

      &__date-area {
        display: flex;
        @include align-items(center);

        select {
          margin: 0 0 0 5px;
        }
      }
    }

    &__info {
      font-size: 10px;
    }
  }

  &__csv {
    margin-bottom: 10px;
    &__select {
      float: left;
      padding: 0 10px;
      margin: 0 10px 0 5px;
    }
    button,
    button:hover,
    button:focus {
      height: 25px;
      padding: 0 10px;
      margin: 0 10px 0 5px;
    }
  }

  &__list {
    .sticky__table__div {
      width: 100%;
      overflow-x: scroll;
    }

    &__table {
      height: calc(100vh - 500px);
      table-layout: auto;
      white-space: nowrap;
    }

    &__th {
      padding: 3px;
      background-color: $gray-color-light;
      font-weight: bold;
    }

    &__td {
      padding: 8px 3px;
    }
  }
}

input.cost-analysis__search__form__button {
  margin-left: 5px;

  &,
  &:hover,
  &:focus {
    height: 25px;
    width: 80px;
    padding: 0 10px;
  }
}
