.draft-trips-new {
  &__header {
    display: flex;
    @include justify-content(space-between);
    border-bottom: 1px solid $gray-color;
    padding-bottom: 10px;

    &__title {
      font-weight: bold;
    }

    &::after {
      content: '';
    }
  }

  &__travelers {
    @include flex-grow(1);
  }

  &__traveler {
    &__header {
      display: flex;
      @include justify-content(space-between);
    }
  }

  &__term {
    @include flex-grow(1);
    display: flex;
  }

  &__outdate {
    @include flex-grow(1);
    background-color: white;
    margin: 0;
  }

  &__homedate {
    @include flex-grow(1);
    background-color: white;
    margin: 0 0 0 10px;
  }
}
