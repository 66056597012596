@mixin select-item-box {
  display: flex;

  border: 2px solid $gray-color-border;
  background: white;
  margin-bottom: 10px;
  font-size: 10px;

  &.selected {
    border-color: $accent-color;
  }

  &.flight {
    @include flex-direction(column);
  }

  &__left {
    padding: 5px 5px 5px 10px;
  }

  &__left-list {
    max-width: 420px;
    padding: 5px 5px 5px 10px;
  }

  &__name {
    font-size: 12px;
    font-weight: bold;
  }

  &__right-gray {
    display: flex;
    @include flex-grow(0);

    background: $gray-color-light;
    margin-left: auto;
    min-width: 170px;
    color: $accent-color;

    &__price-area {
      @include justify-content(center);
      display: flex;
      @include flex-direction(column);
      margin: auto 0 auto 10px;
    }

    &__price {
      font-size: 14px;
      font-weight: bold;
    }

    &__small {
      font-size: 10px;
    }

    &__tax {
      font-size: 10px;
      display: flex;
    }

    &__button-area {
      @include justify-content(center);
      display: flex;
      @include flex-direction(column);
      margin: auto 5px auto auto;
    }

    &__button {
      display: flex;
      @include align-items(center);

      width: 53px;
      height: 30px;
      text-align: center;
      background: white;
      border: 2px solid $accent-color;
      border-radius: 3px;
      padding: auto 2px;
      font-weight: bold;
      line-height: 11px;
      font-size: 11px;
      cursor: pointer;
      text-align: center;

      &.selected {
        background: $accent-color;
        color: white;
        font-size: 9px;
        letter-spacing: -1px;
      }
    }

    &.flight {
      @include flex-direction(column);
      @include justify-content(center);
      padding: 10px 0;
    }

    &__button-text {
      margin: 0 auto;
    }

    &__note {
      color: $red-color;
    }
  }

  &__right {
    margin-left: auto;
  }
}
