.payment__section {
  display: flex;
  @include flex-wrap(wrap);
  padding: 5px 0;
  line-height: 27px;
}

.payment__section-right {
  display: flex;
  width: 300px;
}

$label-width: 130px;

.payment__left-label {
  font-weight: normal;
  width: $label-width;
}

.payment__left-label-img-wrap {
  display: inline-block;
  width: 36px;
  height: 23px;
  margin: 0 0 0 8px;
  vertical-align: middle;
}

.payment__left-label-img {
  display: block;
  max-height: 100%;
}

input.payment__input {
  @include calc(width, '100% - #{$label-width}');
}

input.payment__input-card_number {
  width: 4em;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.payment__slash {
  width: 20px;
  text-align: center;
}

input.payment__expire {
  width: 3em;
}

.payment__description,
.payment__deleting-flash {
  margin: 10px 0;
}

.payment__cards {
  overflow: hidden;
  margin-bottom: 15px;
}

.payment__cards.new {
  margin-top: 15px;
}

.payment__del-card {
  display: inline-block;
  float: right;
}

.payment__error {
  margin-top: 10px;
}

.payment__loading {
  display: block;
  margin: 0 auto;
}

.payment__card-info {
  margin-top: 10px;
}

.payment__card-info-label {
  margin-right: 5px;
  font-weight: bold;
}

.payment__card-info-label-small {
  margin-right: 5px;
  font-size: 8px;
  color: $gray-color-text;
}

.payment__confirm-modal {
  margin: 10px 0;
  padding: 0 10px 10px;
  background: #fff;
  border: solid 1px $gray-color;
  border-radius: 3px;
}

.payment__flash .flash {
  margin: 0 0 10px;
}

.payment__hotel-warning {
  font-size: 12px;
}

.payment__confirm-bedtype-text {
  font-size: 12px;
  color: $gray-color-text;
}

.payment__card-area {
  margin-left: 20px;
}

.payment__new-card-area {
  margin-left: 20px;
}
