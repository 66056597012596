.#{$prefixClass}-full {
  width: 275px;

  &-header {
    padding: 5px 10px;
    text-align: center;
    user-select: none;
    -webkit-user-select: none;
    border-bottom: 1px solid #ccc;
    overflow: hidden;

    &-month-select,
    &-year-select {
      width: 70px;
      float: right;
      margin-right: 5px;
    }

    &-switcher {
      float: right;
      display: inline-block;

      &-normal:hover {
        border-color: #23c0fa;
        box-shadow: 0 0 2px rgba(45, 183, 245, 0.8);
        cursor: pointer;
      }

      &-focus {
        border-color: $accent-color;
        background-color: $accent-color;
        color: #fff;
      }

      > span {
        float: left;
        height: 28px;
        line-height: 24px;
        border: 1px solid #d9d9d9;
        padding: 0 10px;
        color: #666;

        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-right: none;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left: none;
        }
      }
    }
  }
}

.#{$prefixClass}-fullscreen {
  width: auto;

  .#{$prefixClass}-full-header {
    border-bottom: none;
  }

  .#{$prefixClass} {
    &-column-header {
      text-align: right;
      padding-right: 12px;
    }

    &-cell {
      padding: 0;
    }

    &-cell .#{$prefixClass}-date,
    &-month-panel-cell .#{$prefixClass}-month-panel-month {
      display: block;
      height: 116px;
      width: auto;
      border-radius: 0;
      margin: 0 4px;
      border: none;
      border-top: 2px solid #eee;
      text-align: right;
      padding-right: 8px;
    }

    &-selected-day .#{$prefixClass}-date,
    &-month-panel-selected-cell .#{$prefixClass}-month-panel-month {
      background-color: #ebfaff;
      color: #666;
    }

    &-today .#{$prefixClass}-date,
    &-month-panel-selected-cell .#{$prefixClass}-month-panel-month {
      border-top-color: #3fc7fa;
      color: #3fc7fa;
    }
  }
}
