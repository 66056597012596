fieldset:not([class*='Mui']) {
  background-color: lighten($base-border-color, 10%);
  border: $base-border;
  margin: 0 0 $small-spacing;
  padding: $base-spacing;
}

input,
label,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

label {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;

  &.required::after {
    content: '*';
  }

  abbr {
    display: none;
  }
}

#{$all-text-inputs},
select[multiple='multiple'],
select.input,
textarea {
  &:not([class^='Mui']) {
    background-color: $base-background-color;
    border: $base-border;
    border-radius: $base-border-radius;
    box-shadow: $form-box-shadow;
    box-sizing: border-box;
    font-family: $base-font-family;
    font-size: $base-font-size;
    margin-bottom: $base-spacing / 2;
    padding: $base-spacing / 3;
    transition: border-color;
    width: 100%;

    &:hover {
      border-color: darken($base-border-color, 10%);
    }

    &:focus {
      border-color: $action-color;
      box-shadow: $form-box-shadow-focus;
      outline: none;
    }
  }
}

textarea {
  resize: vertical;
}

input[type='search'] {
  @include appearance(none);
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
  margin-right: $small-spacing / 2;
}

input[type='file'] {
  padding-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}
