.#{$prefixClass} {
  $pickerPrefix: #{$prefixClass}-picker;

  .#{$pickerPrefix} {
    @mixin effect() {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      transform-origin: 0 0;
      display: block !important;
    }

    &-slide-up-enter {
      @include effect();
      opacity: 0;
      animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
      animation-play-state: paused;
    }

    &-slide-up-appear {
      @include effect();
      opacity: 0;
      animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
      animation-play-state: paused;
    }

    &-slide-up-leave {
      @include effect();
      opacity: 1;
      animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
      animation-play-state: paused;
    }

    .#{$pickerPrefix}-slide-up-enter.#{$pickerPrefix}-slide-up-enter-active.#{$pickerPrefix}-placement-bottomLeft,
    .#{$pickerPrefix}-slide-up-enter.#{$pickerPrefix}-slide-up-enter-active.#{$pickerPrefix}-placement-bottomRight,
    .#{$pickerPrefix}-slide-up-appear.#{$pickerPrefix}-slide-up-appear-active.#{$pickerPrefix}-placement-bottomLeft,
    .#{$pickerPrefix}-slide-up-appear.#{$pickerPrefix}-slide-up-appear-active.#{$pickerPrefix}-placement-bottomRight {
      animation-name: rcDropdownSlideUpIn;
      animation-play-state: running;
    }

    .#{$pickerPrefix}-slide-up-enter.#{$pickerPrefix}-slide-up-enter-active.#{$pickerPrefix}-placement-topLeft,
    .#{$pickerPrefix}-slide-up-enter.#{$pickerPrefix}-slide-up-enter-active.#{$pickerPrefix}-placement-topRight,
    .#{$pickerPrefix}-slide-up-appear.#{$pickerPrefix}-slide-up-appear-active.#{$pickerPrefix}-placement-topLeft,
    .#{$pickerPrefix}-slide-up-appear.#{$pickerPrefix}-slide-up-appear-active.#{$pickerPrefix}-placement-topRight {
      animation-name: rcDropdownSlideDownIn;
      animation-play-state: running;
    }

    .#{$pickerPrefix}-slide-up-leave.#{$pickerPrefix}-slide-up-leave-active.#{$pickerPrefix}-placement-bottomLeft,
    .#{$pickerPrefix}-slide-up-leave.#{$pickerPrefix}-slide-up-leave-active.#{$pickerPrefix}-placement-bottomRight {
      animation-name: rcDropdownSlideUpOut;
      animation-play-state: running;
    }

    .#{$pickerPrefix}-slide-up-leave.#{$pickerPrefix}-slide-up-leave-active.#{$pickerPrefix}-placement-topLeft,
    .#{$pickerPrefix}-slide-up-leave.#{$pickerPrefix}-slide-up-leave-active.#{$pickerPrefix}-placement-topRight {
      animation-name: rcDropdownSlideDownOut;
      animation-play-state: running;
    }

    @keyframes rcDropdownSlideUpIn {
      0% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
      }

      100% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
      }
    }

    @keyframes rcDropdownSlideUpOut {
      0% {
        opacity: 1;
        transform-origin: 0% 0%;
        transform: scaleY(1);
      }

      100% {
        opacity: 0;
        transform-origin: 0% 0%;
        transform: scaleY(0);
      }
    }

    @keyframes rcDropdownSlideDownIn {
      0% {
        opacity: 0;
        transform-origin: 100% 100%;
        transform: scaleY(0);
      }

      100% {
        opacity: 1;
        transform-origin: 100% 100%;
        transform: scaleY(1);
      }
    }
    @keyframes rcDropdownSlideDownOut {
      0% {
        opacity: 1;
        transform-origin: 100% 100%;
        transform: scaleY(1);
      }

      100% {
        opacity: 0;
        transform-origin: 100% 100%;
        transform: scaleY(0);
      }
    }
  }
}
