.select-hotel-box {
  &__button {
    &__icon {
      margin-left: 10px;
    }
  }
}

.select__sp {
  .map {
    .select-hotel-box__image-area {
      width: auto;
      height: auto;
    }
  }
}

.map {
  .select-hotel-box {
    @include flex-wrap(nowrap);
    min-width: 360px;
  }
}
