$prefix: 'form-elements-text-field';

.#{$prefix} {
  margin-bottom: 20px;

  &__label {
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    color: $accent-color;
    margin-bottom: 3px;
  }

  .#{$prefix}__input[type='number']::-webkit-outer-spin-button,
  .#{$prefix}__input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .#{$prefix}__input[type='text'],
  .#{$prefix}__input[type='password'],
  .#{$prefix}__input[type='number'] {
    margin: 0;

    &,
    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
      padding: 5px 0;
      border-bottom: 1px solid $accent-color;
    }

    &:focus {
      border-bottom: 3px solid $accent-color;
    }
  }

  &__bottom {
    height: 12px;
    font-size: 12px;
    font-weight: normal;
    color: $accent-color;
  }
}

.error {
  .#{$prefix} {
    &__label {
      color: $red-color;
    }

    &__input[type='text'] {
      &,
      &:hover,
      &:focus {
        border-color: $red-color;
      }
    }

    &__bottom {
      color: $red-color;
    }
  }
}
