//
// photo list
//

.hotel-modal__thumb-area {
  display: flex;
  @include flex-wrap(wrap);
  position: relative;
}

.hotel-modal__thumb-container {
  @include calc(width, '(100% - 10px) / 3');
  margin-bottom: 5px;
  position: relative;
}

.hotel-modal__thumb-container:after {
  padding-top: 100%;
  content: '';
  display: block;
}

.hotel-modal__thumb {
  position: absolute;
  height: 100%;
  width: 100%;
}

.hotel-modal__thumb-container:nth-child(3n + 2) {
  margin-left: 5px;
  margin-right: 5px;
}

.hotel-modal__close-photos {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  color: white;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.hotel-modal__more-photos {
  margin: 0 auto;
  padding: 10px $base-margin;
}

//
// detail
//

.hotel-modal__image {
  height: 210px;
  overflow: hidden;
  cursor: pointer;
}

.hotel-modal__info {
  padding: $base-margin;
  width: 50%;
}

.hotel-modal__detail {
  width: 50%;
  margin-top: $base-margin;
  margin-bottom: 10px;
}

.hotel-modal__detail-title {
  background: $gray-color-light;
  color: $gray-color-text;
  width: 100%;
  text-align: center;
  font-size: 10px;
  padding: 3px;
}

.hotel-modal__detail-value {
  color: $gray-color-text;
  width: 100%;
  text-align: center;
  font-size: 10px;
  padding: 8px;
  border: 1px solid $gray-color-light;
}

.hotel-modal__actions {
  display: flex;
  margin-bottom: 10px;
  border-top: 1px solid $gray-color-light;
  border-bottom: 1px solid $gray-color-light;
}

.hotel-modal__map {
  width: 100%;
  text-align: center;
  color: $base-color;
  padding: $base-margin 0;
  cursor: pointer;
}

.hotel-modal__streetview,
.hotel-modal__route {
  width: 34%;
  text-align: center;
  color: $gray-color-disabled;
  padding-top: $base-margin;
}

.hotel-modal__select {
  max-width: $content-width;
  margin: 0 auto;
  padding: $base-margin;
}

.hotel-modal__select .hotel-modal__submit {
  @include big-button;
  text-align: center;
}

.hotel-modal__description.error {
  margin-bottom: 10px;
}

.icon {
  margin-right: 20px;
  margin-left: 10px;
}

.hotel-modal__detail-icon {
  padding: 10px 30px 0;
  display: flex;
  margin-bottom: 10px;
}

.hotel-modal__detail-frame {
  font-size: 10px;
  padding-right: 10px;
}

.modal__body-without-padding {
  .hotel-modal__image-relative {
    .hotel-modal__modal-image {
      height: 150px;
      background-size: cover;
    }
  }
}

.hotel-modal__detail-explain,
.hotel-modal__detail-explain-other {
  padding-left: 40px;
  padding-right: 40px;
  color: $gray-color-text;
  margin-bottom: 20px;
  font-size: 13px;
}

.hotel-modal__detail-style {
  list-style-type: disc;
  list-style-position: inside;
}

.hotel-modal__detail-explain {
  font-size: 15px;
}

.hotel-modal__price-detail,
.hotel-modal__additional-charge {
  font-size: 10px;
}

.hotel-modal__price-detail {
  margin-left: 10px;
}

.hotel-modal__detail-explain-other {
  h5 {
    font-weight: bold;
  }

  p,
  li {
    font-weight: normal;
  }
}

.hotel-modal__detail-more {
  cursor: pointer;
  display: block;
  padding-top: 5px;
}

.hotel-modal__detail-more.close {
  margin-left: 15px;
}
