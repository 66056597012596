.confirm__message-area {
  margin-bottom: $base-margin;
}

.confirm__button-area {
  text-align: right;
}

.confirm__button {
  &,
  &:hover,
  &:focus {
    background-color: $base-color;
  }
}

.confirm__button.confirm {
  margin-right: 10px;
}
