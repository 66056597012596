$pc-width: 1150px;

.biz-sp {
  display: none;
}

@media screen and (max-width: $pc-width) {
  .biz-pc {
    display: none;
  }

  .biz-sp {
    display: block;
  }
}

//PC
@mixin biz-cover-bg($url, $height) {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;

  &:after {
    background: url($url) no-repeat center center;
    background-size: cover;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
}

.biz-pc__main-top {
  @include biz-cover-bg('/images/biz_lp/top_bg.png', 700px);
}

.biz-pc__main-in {
  width: 1050px;
  margin: 0 auto;
}

.biz-pc__logo,
.biz-sp__logo {
  padding: 20px 0 0;
  display: flex;

  a {
    display: block;
  }
}

.biz-pc__login,
.biz-sp__login {
  display: block;
  margin: 15px 20px 0 auto;
  color: $white-color;
}

.biz-pc__top-wrapper {
  margin-top: 100px;
  max-width: 1050px;
  width: 100%;
  padding-bottom: 225px;
  text-align: left;
}

.biz-pc__sub-titile {
  margin: 40px 0;
  color: white;
  font-size: 14px;
  letter-spacing: 7px;
  line-height: 25px;
}

.biz-pc__apply-area,
.biz-pc__wp-dl-button {
  display: flex;
}

.biz-pc__apply-area {
  display: flex;
  flex-direction: column;

  a {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ac9355;
    border-radius: 3px;
    width: 325px;
    height: 50px;
    padding: 16px 15px 16px 18px;
    font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
    color: white;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2em;

    &:after {
      position: absolute;
      content: '';
      right: 15px;
      top: 51%;
      margin-top: -8px;
      width: 11px;
      height: 16px;
      background-image: url('/images/biz_lp/btn_LinkIcon.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:first-child {
      background-color: #7d6e49;
      margin-bottom: 15px;
    }
  }
}

.biz-pc__wp-dl-button {
  @include justify-content(center);
  display: block;
  padding: 20px;
  background-color: $base-color;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;

  a {
    color: $white-color;
    font-size: 20px;
    font-weight: 600;
  }
}

.biz-pc__main-content {
  padding: 100px 0;
  background-color: $gray-color-border;
}

.biz-pc__main-whitepaper,
.biz-sp__main-whitepaper {
  padding: 100px 20px;
  background-color: $white-color;
}

.biz-pc__title {
  padding: 0 150px 70px;
  text-align: center;

  p {
    font-size: 30px;
  }
}

.biz-pc__wp-title {
  padding: 0 120px 50px;

  p {
    font-size: 33px;
  }
}

.biz-pc__wp-main-text,
.biz-sp__wp-main-text {
  font-weight: 600;
  text-align: center;
}

.biz-sp__wp-main-text {
  margin-bottom: 20px;

  p {
    font-size: 25px;
  }
}

.biz-pc__wp-sub-text {
  width: 100%;
  display: flex;
  text-align: center;
  margin-top: 20px;

  p {
    font-size: 16px;
    color: $gray-color-text;
  }
}

.biz-pc__wp-sub-left {
  display: block;
  width: 30%;
}

.biz-pc__wp-sub-right {
  display: block;
  width: 70%;
  text-align: left;
  margin: 10px 0 0 20px;
}

.biz-pc__content-wrapper {
  max-width: 1050px;
  width: 100%;
  max-height: 450px;
  height: 100%;
}

.biz-pc__content-left-step {
  max-width: 320px;
  width: 30%;
  border-right: 1px solid $gray-color-border;
  display: flex;
  flex-direction: column;
  background-color: $white-color;
}

.biz-pc__content-step_off {
  max-width: 320px;
  background-color: $white-color;
  display: flex;
  @include align-items(center);
  @include justify-content(center);
  @include flex-grow(1);

  p {
    font-size: 20px;
    color: $gray-color;
    font-weight: bold;
  }

  .span {
    margin-right: 10px;
  }
}

.biz-pc__content-step_on {
  max-width: 320px;
  background-color: $white-color;
  display: flex;
  @include align-items(center);
  @include justify-content(center);
  @include flex-grow(1);
  border-left: 3px solid $accent-color;

  p {
    font-size: 20px;
    color: $accent-color;
    font-weight: bold;
  }

  span.number {
    color: $accent-color;
    margin-right: 10px;
  }

  span {
    color: $black-color;
  }
}

.biz-pc__content-step_on + .biz-pc__content-step_off {
  border-top: 1px solid $gray-color-border;
}

.biz-pc__content-step_off + .biz-pc__content-step_off {
  border-top: 1px solid $gray-color-border;
}

.biz-pc__content-step_off + .biz-pc__content-step_on {
  border-top: 1px solid $gray-color-border;
}

.biz-pc__content-right-detail {
  display: block;
  height: 450px;
}

.biz-pc__main-detail {
  padding: 100px 0;
  background-color: white;
}

.biz-pc__detail-wrapper {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
}

.biz-pc__detail-contents {
  max-width: 525px;
  width: 100%;
}

.biz-pc__detail-item {
  max-width: 525px;
  width: 100%;
  margin-bottom: 18px;
}

.biz-pc__detail-item-text {
  margin: 10px 0 0 15px;

  p {
    font-size: 22px;
    font-weight: 800;
  }

  p.small {
    font-weight: 500;
    font-size: 18px;
  }
}

.biz-pc__movie-container,
.biz-sp__movie-container {
  padding: 60px 20px;
  background-color: $white-color;
  text-align: center;
  border-top: 1px solid $gray-color-border;
}

.biz-pc__service-video,
.biz-sp__service-video {
  display: inline-block;

  .biz-sp__service-video {
    width: 100%;
    height: 100%;
  }
}

.biz-pc__interview-container,
.biz-sp__interview-container {
  padding: 60px 20px 0;
  background-color: $white-color;
  text-align: center;
  border-top: 1px solid $gray-color-border;
}

.biz-pc__interview-container p,
.biz-sp__interview-container p {
  text-align: center;
  margin-bottom: 20px;
}

.biz-pc__interview-wrapper:first-child {
  margin: 0 auto;
}

.biz-pc__interview-wrapper:last-child {
  padding-bottom: 60px;
}

.biz-pc__interview-wrapper {
  display: flex;
  max-width: 1050px;
  margin: 60px auto 0;
}

.biz-sp__interview-wrapper,
.biz-sp__interview-contents {
  padding-bottom: 40px;

  img {
    max-width: 500px;
    width: 100%;
  }
}

.biz-sp__interview-subtitle {
  font-weight: 700;
  margin-bottom: 20px;
}

.biz-pc__interview-right-contents {
  margin-left: 40px;
}

.biz-pc__interview-right-subtitle {
  margin: 0 40px;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.biz-pc__interview-right-text {
  margin: 40px 0 0 40px;

  p {
    text-align: left;
    margin-bottom: 0;
  }

  a {
    display: block;
    text-align: left;
    margin-top: 20px;
  }
}

//SP
.biz-sp__main-top {
  padding: 0 30px 100px;
  @include biz-cover-bg('/images/biz_lp/top_bg.png', 700px);
}

.biz-sp__logo {
  padding: 20px 0 0;
  text-align: left;
}

.biz-sp__top-wrapper {
  max-width: 521px;
  width: 100%;
  margin: 100px 0 0;
}

.biz-sp__title {
  text-align: center;
  margin-bottom: 20px;

  img {
    max-width: 521px;
    width: 100%;
    max-height: 96px;
    height: 100%;
  }
}

.biz-sp__title-wp {
  text-align: center;
  margin-bottom: 20px;
}

.biz-sp__sub-titile {
  margin: 34px 0;
}

.biz-sp__apply-area {
  padding-bottom: 50px;

  a {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ac9355;
    border-radius: 3px;
    width: 100%;
    height: 45px;
    padding: 16px 15px 16px 18px;
    font-family: '游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
    color: white;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2em;

    &:after {
      position: absolute;
      content: '';
      right: 15px;
      top: 51%;
      margin-top: -8px;
      width: 11px;
      height: 16px;
      background-image: url('/images/biz_lp/btn_LinkIcon.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:first-child {
      background-color: #7d6e49;
      margin-bottom: 15px;
    }
  }
}

.biz-sp__main-content {
  padding: 50px 30px;
  background-color: $gray-color-border;
}

.biz-sp__mobile-main-content {
  padding: 50px 30px;
  background-color: $white-color;
  border-bottom: 1px solid $gray-color-border;
}

.biz-sp__content-title,
.biz-sp__detail-title {
  padding-bottom: 47px;
  display: flex;
  flex-wrap: wrap;
}

.biz-sp__content-first,
.biz-sp__detail-first {
  height: 22px;
  margin: 0 5px 10px 0;
}

.biz-sp__content-last,
.biz-sp__detail-last {
  height: 22px;
}

.biz-sp__content-wrapper {
  p {
    display: block;
    margin: 0 auto;
  }
}

.biz-sp__content-step {
  text-align: center;
  display: block;
  background-color: white;
  padding: 20px 0;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid $gray-color-border;
  margin-bottom: 30px;

  span.number {
    color: $accent-color;
    margin-right: 10px;
  }

  span {
    color: $black-color;
  }

  img {
    max-width: 729px;
    width: 100%;
  }
}

.biz-sp__content-step-small {
  padding: 20px 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;

  span.number {
    color: $accent-color;
    margin-right: 10px;
  }

  img {
    margin-top: 10px;
    max-width: 729px;
    width: 100%;
  }

  span {
    color: $black-color;
  }
}

$biz-title-width: 650px;

.biz-sp__middle {
  display: none;
}

@media screen and (max-width: $biz-title-width) {
  .biz-sp__large {
    display: none;
  }

  .biz-sp__middle {
    display: block;
  }
}

$biz-mobile-width: 430px;

.biz-sp__small {
  display: none;
}

@media screen and (max-width: $biz-mobile-width) {
  .biz-sp__wide {
    display: none;
  }

  .biz-sp__small {
    display: block;
    border-top: 1px solid $gray-color-border;
  }

  .biz-sp__main-top {
    .biz-sp__small {
      border: none;
    }
  }
}

.biz-sp__main-detail {
  padding: 50px 30px;
  background-color: $white-color;
}

.biz-sp__detail-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.biz-sp__detail-item {
  max-width: 525px;
  width: 100%;
  margin-bottom: 18px;
}

.biz-sp__detail-item-text {
  margin-left: 15px;

  p {
    font-size: 16px;
    font-weight: 600;
  }

  .small {
    font-weight: 400;
    margin-top: 3px;
    font-size: 11px;
  }
}

.biz-sp__small .biz-sp__content-title,
.biz-sp__small .biz-sp__detail-title {
  padding-bottom: 47px;
}

.biz-sp__detail-title img,
.biz-sp__content-title img {
  margin: 0 auto;
}

.biz-sp__main-footer {
  padding: 42px 20px;
  background-color: black;
}

.biz-sp__footer-content {
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  a {
    color: $white-color;
  }
}

.biz-sp__footer-content-small {
  font-size: 12px;
  display: flex;
  flex-direction: column;

  a {
    color: $white-color;
    margin-bottom: 10px;
  }
}

.biz-sp__footer-logo {
  color: $white-color;
  text-align: center;
  margin-top: 20px;
}

.biz-sp__main-in {
  max-width: 1050px;
  width: 100%;
}

.biz-sp__wp-sub-img {
  max-width: 250px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
}

.biz-sp__wp-sub-text {
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  font-size: 16px;
  color: $gray-color-text;
}
