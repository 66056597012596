header {
  width: 100%;
  height: $header-height;
}

header.home-header {
  height: 0;
}

header.home-header .header-in {
  height: 0;
  background: none;
  border: 0;
}

header.home-header {
  .trips-button,
  .trips-home-button,
  .biz-button {
    position: relative;
    z-index: 10;
  }
}

.header-in {
  display: flex;
  background: $black-color;
  border-bottom: 1px solid $base-color;
  height: $header-height;
}

.logo {
  display: block;
  padding: 10px;
}

.header__right-link {
  margin: 15px $base-margin 0 auto;
}
