.organization-analysis {
  &__wrap {
    padding: 10px 10px 40px 10px;
    border-bottom: 1px solid $gray-color-border;
    margin-bottom: 40px;
  }

  &__trip-analysis {
    display: flex;
    @include flex-wrap(wrap);
  }

  &__title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__item {
    @include flex-basis(50%);
  }

  &__add-item {
    display: flex;
    @include flex-direction(column);
    @include justify-content(center);
    width: 80%;
    height: 200px;
    margin: 10px auto;
    border: dashed 5px $gray-color-border;
    border-radius: 1em;
    cursor: pointer;
    text-align: center;
    color: $gray-color-text;

    &__icon {
      font-size: 4em;
    }

    &__text {
      font-size: 1.5em;
    }
  }

  &__dim-area {
    display: flex;
    @include align-items(center);

    select {
      margin-bottom: 5px;
      margin-right: 10px;
      height: 25px;
    }
  }

  &__chart-type-area {
    display: flex;
    @include align-items(center);

    margin-left: 10px;

    select {
      margin-bottom: 0;
      height: 25px;
    }
  }

  &__reload-button-area {
    margin-left: 10px;
  }

  &__remove-item-button {
    padding: 0.5em 0.75em;
  }

  &__chart-area {
    width: 98%;
  }
}
