.modal__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal__wrapper-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.modal__box,
.modal-pc__box {
  position: absolute;
  left: 50%;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 90vw;
  margin: auto;
  font-size: 14px;
  background: $gray-color-light;
}

.modal-pc__box {
  max-width: 700px;
}

.modal__header {
  background: $black-color;
}

.modal__header-title {
  color: white;
  font-size: 18px;
  line-height: 48px;
  height: 72px;
  width: 100%;
  text-align: center;
  padding-top: 12px;
}

.modal__close-button {
  position: absolute;
  right: 10px;
  top: 12px;
  margin-left: auto;
  width: 24px;
  cursor: pointer;
}

.modal__body {
  padding: $base-margin;
  max-height: 80vh;
  overflow-y: scroll;
}

.modal__body-without-padding {
  padding: 0;
}
