@mixin main_color {
  background-color: white;
  color: #af985e;
}

@mixin main_color_background {
  background-color: #af985e;
  color: white !important;
}

.calendar-input-div {
  position: relative;
  padding: 0;
}

.search-calendar-input {
  width: 80px !important;
}

.inner-calendar-input-textbox {
  padding: 3px 5px;
}

.open-calendar-input {
  position: absolute;
  border: 2px solid #af985e;
  z-index: 2;
  background-color: white;
  min-width: 45%;
  max-width: 80%;
}

.calendar-wrapper {
  margin: 20px auto;
  max-width: 320px;
  font-weight: bold;
  font-size: 13px;
}

.close-calendar-input {
  display: none;
}

#calendar {
  width: 640px;
  height: 480px;
  margin: 100px auto;
}

.Calendar-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.calendar-textbox {
  margin: 5px 0 0;
}

.calendar-date-textbox {
  margin: 5px 10px 0;
}

.Calendar-header {
  @include main_color;
  display: flex;
  @include align-items(center);

  text-align: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.Calendar-header button {
  width: 50px;
  font-size: 20px;
  border: 0;
  background: transparent;
  color: #af985e;
  cursor: pointer;
  padding: 0;
}

.Calendar-grid-item {
  @include main_color;
  flex: 0 14.28571%;
  text-align: center;
  cursor: pointer;
  padding: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cannotClick {
  pointer-events: none;
}

.prevMonth,
.nextMonth {
  color: #999 !important;
}

.beforeToday {
  color: #eee !important;
  pointer-events: none;
}

.search__select-row,
.search__button-row {
  @include clearfix;
}

.search__select-row {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin search__select-row-box {
  border: 1px solid #af985e;
  width: 100%;
  max-width: 100px;
  height: 40px;
  @include margin(15px 2px);
}

.search__select-time {
  @include search__select-row-box;
  @include main_color;
  height: 37px;
  border-radius: 0;
  cursor: pointer;
}

.search_calendar_button {
  @include main_color;
  border-width: 0;
}

.search__radio-type {
  @include margin(15px 2px);
  padding: 3px;
  border: 1px solid #af985e;
  flex-basis: 100px;
  white-space: nowrap;
}

.search__radio-type input[type='radio'] {
  display: none;
}

.search__radio-type label {
  @include main_color;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}

.search__radio-type input[type='radio']:checked + label {
  @include main_color_background;
}

.Calendar-header-currentDate {
  color: black;
  font-weight: bold;
}

.Calendar-grid-item--current {
  @include main_color_background;
  border-radius: 20px;
}

.search__input.search-calendar-input.selected-date-textbox {
  border: 2px solid #af985e;
  border-bottom: 0;
}
