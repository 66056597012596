.select-animation-box {
  &-enter {
    opacity: 0.01;
    transform: scale(0.7);
  }

  &-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 500ms;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0.01;
    transition: all 500ms;
  }
}
