// Typography
$base-font-family: $helvetica;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$base-color: #af985e;
$base-color-light: lighten($base-color, 30%);
$accent-color: #af985e;
$accent-color2: #cc9200;
$accent-color-light: #f9f7f3;
$text-color: #404040;
$red-color: #bf0000;
$red-color-light: rgba($red-color, 0.3);
$green-color: #28a745;
$green-color-light: rgba($green-color, 0.3);
$white-color: #ffffff;
$gray-color: #eee;
$blue-color-light: #bde8fd;
$gray-color-light: #f7f7f7;
$gray-color-light2: #f0efef;
$gray-color-disabled: #aaa;
$gray-color-border: #eee;
$gray-color-text: #7d7d7d;
$black-color: #292929;
$yellow-color: #e5aa00;
$flash-bg-color: #d8f2ff;
$flash-border-color: #a9cee0;
$flash-text-color: #35a4da;
$flash-error-bg-color: #f2b8bd;

// Font Colors
$base-background-color: #fff;
$base-font-color: $text-color;
$action-color: $base-color;

// Border
$base-border-color: $gray-color-border;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Service Colors
$accent-color-mynavi: #22bbff;
