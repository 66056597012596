@mixin normal-button($base-color: $action-color) {
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  background-color: $base-color;
  border-radius: $base-border-radius;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    &:not([class^='Mui']) {
      background-color: darken($base-color, 15%);
      color: #fff;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

#{$all-button-inputs},
button {
  @include normal-button;
}
