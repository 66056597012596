.organization-trips-detail {
  display: flex;
  width: 100%;
  background: $gray-color-light;

  &__meta-panel {
    @include flex-grow(1);
    margin: 10px;
    background-color: $white-color;

    &__section {
      padding: 10px;
      border-bottom: solid 5px $gray-color-light;
    }

    &__section:last-child {
      border-bottom: none;
    }

    &__label {
      color: $gray-color-text;
      margin-bottom: 5px;
    }

    &__trip-id {
      font-weight: bold;
    }

    &__name {
      font-weight: bold;
    }

    &__traveler {
      margin-bottom: 5px;
    }

    &__traveler-info {
      font-size: 0.85em;
    }

    &__project-select {
      width: 100px;
    }

    &__button-container {
      button {
        padding: 6px;
        width: 80px;

        + button {
          margin-left: 5px;
        }
      }
    }
  }

  &__detail-panel {
    @include flex-grow(2);
    margin: 10px;
    padding: 10px;
    background-color: $white-color;

    &__label {
      color: $gray-color-text;
      margin-bottom: 5px;
    }

    &__item-block {
      &__header {
        display: flex;

        &__icon {
          text-align: center;
          width: 20px;
          height: 20px;
          background: $accent-color;
          border-radius: 10px;
          margin-right: 10px;
          background-size: 20px 20px;

          &.icon--hotel {
            background-image: url('/images/organization/trips/icons/hotel.png');
          }

          &.icon--shinkansen {
            background-image: url('/images/organization/trips/icons/shinkansen.png');
          }

          &.icon--air {
            background-image: url('/images/organization/trips/icons/air.png');
          }

          &.icon--rental_car {
            background-image: url('/images/organization/trips/icons/rental_car.png');
          }

          &.icon--train {
            background-image: url('/images/organization/trips/icons/train.png');
          }

          &.icon--bus {
            background-image: url('/images/organization/trips/icons/bus.png');
          }
        }

        &__date {
          display: flex;
          font-weight: bold;
        }

        &__remove {
          margin-left: auto;
        }

        .tooltip__icon-info {
          margin-right: 10px;
        }

        &__flex {
          display: flex;
          @include flex-wrap(wrap);
        }
      }

      &__body {
        margin-left: 8px;
        border-left: solid 4px $accent-color;
        padding: 10px 0 10px 20px;

        &.no-border {
          border: none;
          margin-left: 12px;
        }

        &__content {
          display: flex;
          @include justify-content(space-between);
        }
      }

      &__market-log-button {
        display: flex;
        @include align-items(center);
        color: $base-color;
        font-weight: bold;
        background-color: $gray-color-light;
        border-radius: 6px;
        padding: 0 20px;

        &.disabled {
          color: $gray-color-text;
          pointer-events: none;
        }
      }

      &__market-log-link {
        margin-left: 10px;
        width: 15px;

        &.disabled {
          filter: grayscale(100%);
        }
      }

      &__market-log-link-confirm {
        margin-left: 5px;
        width: 10px;
        height: 10px;
        vertical-align: middle;

        &.disabled {
          filter: grayscale(100%);
        }
      }
    }
  }
}
