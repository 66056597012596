$travel_term_label_width: 150px;

.travel_terms {
  @include responsive(0);

  &__small {
    font-size: 10px;
    margin-bottom: 5px;
    margin-left: $travel_term_label_width + 10px;
  }

  .key-value-table__label {
    width: $travel_term_label_width;
  }
}
