.charges {
  @include responsive(0);

  &__table {
    margin: 10px 0 20px;
  }

  &__add-text {
    font-size: 12px;
    color: $gray-color-text;
  }

  &__small {
    font-size: 11px;
    margin-top: 10px;
  }

  &__th,
  &__td {
    border: solid 1px $gray-color;
    padding: 2px 4px;
    font-size: 12px;
    line-height: 20px;
  }

  &__th {
    background-color: $gray-color-light;
  }

  &__td:last-child {
    min-width: 100px;
  }
}
