.biz-inquiry__form-label {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin: 30px 0;
}

.biz-inquiry__form {
  background-color: $white-color;
  padding: 40px;
  border: 1px solid $gray-color;
  border-radius: 5px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  label {
    margin-bottom: 5px;
  }

  input[type='text'] {
    font-size: 18px;
  }

  span.red {
    color: $red-color;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 3px;
  }

  input[type='submit'] {
    font-size: 20px;
  }
}

.biz-inquiry__content-wrapper {
  background-color: $white-color;
  padding: 20px;
  flex-grow: 9999;
}

.biz-inquiry__checkbox {
  font-weight: normal;
  cursor: pointer;
}

.biz-inquiry__finish {
  background-color: $white-color;
  text-align: center;
}

.biz-inquiry__finish-label {
  padding: 30px 0;
  font-size: 20px;
  font-weight: bold;
}

.biz-inquiry__finish-text {
  padding-bottom: 30px;
}

.biz-inquiry__error-messages {
  margin: 0 20px 10px;
}
