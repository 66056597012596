@mixin button-base {
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;

  &,
  &:hover,
  &:focus {
    background-color: $accent-color;
    color: white;
  }
}

@mixin button-header {
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;

  &,
  &:hover,
  &:focus {
    color: white;
  }
}

@mixin big-button {
  @include button-base;
  margin: 0 auto;
  display: block;
  max-width: $content-width;
  padding: 10px 0;
  font-size: 20px;
  line-height: 1.5;
  height: 50px;
}

input[type='submit'].big-button {
  @include big-button;
}

@mixin round-button {
  cursor: pointer;
  color: $accent-color;
  text-align: center;
  padding: 5px 20px;
  margin: auto 10px;
  border: 2px solid $accent-color;
  border-radius: 30px;
  background: white;
}

@mixin round-button-hilighted {
  @include round-button;
  background: $accent-color;

  &,
  &:hover,
  &:focus {
    color: white;
  }
}

html {
  min-height: 100vh;
}

@import url('//fonts.googleapis.com/earlyaccess/notosansjapanese.css');

body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  font-size: 15px;
  color: $text-color;
  font-family: 'Noto Sans Japanese', sans-serif;
  letter-spacing: 0.03em;
}

h1,
p,
label {
  margin: 0;
}

#content {
  min-height: 100vh;
}

#content > :first-child {
  display: flex;
  @include flex-direction(column);
  min-height: 100vh;
  margin: 0 auto;
}

a.button {
  @include button-base;
  padding: 5px 10px;
  width: auto;
}

.content-body {
  @include flex-grow(9999);
  display: flex;
  @include flex-direction(column);
  max-width: $content-width;
  width: 100%;
  min-height: 0%;
  margin-left: auto;
  margin-right: auto;
  background: white;
}

.content-body-in {
  @include flex-grow(9999);
  display: flex;
  @include flex-direction(column);
  padding: 20px;
}

.red {
  color: $red-color;
}

.green {
  color: green;
}

.yellow {
  color: $yellow-color;
}

.gray {
  color: $gray-color-text;
}

.gray-bg {
  background: $gray-color-light;
}

.select__transit-red {
  color: $red-color;
}

.accent {
  color: $accent-color;
  font-weight: bold;
}

.error {
  color: $red-color;
}

.notice {
  color: $flash-text-color;
}

.success {
  color: green;
}

.flex {
  display: flex;
}

.flex-right {
  margin-left: auto;
}

.flex-wrap {
  @include flex-wrap(wrap);
}

.height100 {
  height: 100%;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.line-through {
  text-decoration: line-through;
}

.white {
  color: white;
}

div.disabled {
  background: #ccc;
  cursor: default;
}

@mixin simple-card {
  background: $base-background-color;
  border-radius: 10px;
  font-size: 14px;
  border: solid 1px $gray-color;
  margin-bottom: 10px;
  overflow: hidden;
}

.simple-card {
  @include simple-card;
}

.modal-container {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;
  background: $gray-color-light;
}

.modal-container__header {
  display: flex;
  background: $gray-color-light;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.modal-container__title {
  margin: 0 auto;
  width: 100%;
}

.modal-container__close {
  width: 20px;
  margin: -10px;
  padding: 10px 20px;
  display: block;
  font-weight: bold;
  cursor: pointer;
}

.modal-container__body {
  max-width: $content-width;
  margin: $base-margin auto 0;
}

.modal-container__body input[type='submit'] {
  @include big-button;
}

input[type='submit'],
input[type='submit']:hover,
input[type='submit']:focus {
  @include button-base;
}

.flash {
  margin: 10px;
  padding: 10px;
  background: $flash-bg-color;
  border: 1px solid $flash-border-color;
  border-radius: 3px;
  text-align: center;
  color: $flash-text-color;

  a {
    &,
    &:hover,
    &:focus {
      color: $flash-text-color;
    }
  }
}

.info-content {
  @include flex-grow(9999);
  border: 10px solid $gray-color-border;
  padding: 10px;
}

.info-content__title {
  font-size: 20px;
  border-bottom: 1px solid $gray-color-border;
  padding-bottom: 10px;

  &__link {
    display: inline-block;
    margin-top: 10px;
  }
}

.info-content__subtitle {
  margin-top: 20px;
}

.sp {
  display: none;
}

@media screen and (max-width: $pc-width) {
  .pc {
    display: none;
  }

  .sp {
    display: block;
  }
}

//
// PC
//

@mixin pc {
  min-height: 100vh;

  > :first-child {
    display: flex;
    @include flex-direction(column);
    min-height: 100vh;
    margin: 0 auto;
  }

  header {
    background: $black-color;
    border-bottom: 1px solid $base-color;
  }

  .header-in {
    max-width: 100%;
    background: none;
    border-bottom: none;
    padding: 0 20px;
  }

  .logo {
    padding-left: 0;
  }

  .trips-button {
    margin-right: 0;
  }

  .in-advance-button {
    margin-right: 10px;
  }

  .content-wrapper {
    @include flex-grow(9999);
    display: flex;
    @include flex-direction(column);
    min-height: 0%;
    width: 100%;
    background: $gray-color;
  }

  .content-body {
    max-width: $pc-width;
    padding: $pc-base-margin;
    background: none;
  }

  .info-content {
    position: relative;
    background: white;

    &__title {
      &__link {
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0;
      }
    }
  }
}

.pc,
.pc-only {
  @include pc;
}

@mixin sp {
  min-height: 100vh;

  > :first-child {
    display: flex;
    @include flex-direction(column);
    min-height: 100vh;
    margin: 0 auto;
  }

  .header-in {
    max-width: 100%;
  }

  .content-wrapper {
    @include flex-grow(9999);
    display: flex;
    @include flex-direction(column);
    width: 100%;
    background: $gray-color;
  }

  .content-body {
    max-width: 100%;
    padding: 10px;
  }
}

@mixin responsive($width) {
  min-height: 100vh;

  &__pc {
    @include pc;
    display: block;
  }

  &__sp {
    @include sp;
    display: none;
  }

  @media screen and (max-width: $width) {
    &__pc {
      display: none;
    }

    &__sp {
      display: block;
    }
  }
}

@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}

@mixin flex-scroll {
  @include flex-grow(1);
  @include flex-basis(0);

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.header-icon__pc {
  margin-left: auto;
  display: block;
}

.header-icon__sp {
  display: none;
}

@media screen and (max-width: 1000px) {
  .header-icon__pc {
    display: none;
  }

  .header-icon__sp {
    margin-left: auto;
    display: block;
  }
}
