@import 'bourbon';
@import '../base/variables';
@import './variables';
@import '../base/base';
@import 'neat';
@import './confirm';
@import './editable_table';
@import './flash_message';
@import './form_elements/text_field';
@import './form_elements/text_area';
@import './form_elements/select_field';
@import './form_elements/radio_field';
@import './form_elements/checkbox_field';
@import './header';
@import './json_inspector';
@import './layout';
@import './loading_animation';
@import './loading_animation-css-loader';
@import './modal';
@import './rc-calendar';
@import './rc-time-picker';
@import './simple_loading';
@import './sticky_table';
@import './toastify';

.text {
  &--muted {
    color: $gray-color-text;
  }

  &--primary {
    color: $base-color;
  }

  &--success {
    color: $green-color;
  }

  &--warning {
    color: $yellow-color;
  }

  &--danger {
    color: $red-color;
  }
}

.bg {
  &--success {
    background-color: $green-color;
  }

  &--warning {
    background-color: $yellow-color;
  }

  &--danger {
    background-color: $red-color;
  }
}

.logo-img {
  background-image: url('/images/logo/aitravel/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 28px;
}

.logo-img2 {
  background-image: url('/images/logo/aitravel/logo2.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 159px;
  height: 40px;
}
