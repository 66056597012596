$panelSelectPrefix: #{$prefixClass}-panel-select;

.#{$panelSelectPrefix} {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative; // Fix chrome weird render bug

  &-active {
    overflow-y: auto;
  }

  &:first-child {
    border-left: 0;
    margin-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  li {
    list-style: none;
    box-sizing: content-box;
    margin: 0;
    padding: 0 0 0 16px;
    height: 24px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: #edfaff;
    }
  }

  li.#{$panelSelectPrefix}-option-selected {
    background: #f7f7f7;
    font-weight: bold;
  }

  li.#{$panelSelectPrefix}-option-disabled {
    color: #ccc;

    &:hover {
      background: transparent;
      cursor: not-allowed;
    }
  }
}
