.reserve_confirm {
  min-height: 100vh;
}

.reserve_confirm__pc {
  @include pc;
  display: block;
}

.reserve_confirm__sp {
  @include sp;
  display: none;
}

@media screen and (max-width: 1000px) {
  .reserve_confirm__pc {
    display: none;
  }

  .reserve_confirm__sp {
    display: block;
  }
}

//
// Common
//

.reserve-confirm__wrap {
  display: flex;
}

.reserve-confirm__wrap-header {
  text-align: center;
  margin-bottom: 20px;
}

.reserve-confirm__title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.reserve-confirm__content-wrapper {
  padding-top: 20px;
}

$right-navi-width: 400px;

.reserve-confirm__wrap-content {
  @include calc(width, '100% - #{$right-navi-width} - 20px');
  background-color: white;
  padding: 10px 20px;
}

.reserve-confirm__wrap-right-navi {
  width: $right-navi-width;
  margin-left: 20px;
  padding: 10px 20px;
  font-size: 13px;
  min-height: 300px;
  //height: 100%;
  background-color: white;
}

.reserve-confirm__section {
  padding-bottom: 20px;
}

.reserve-confirm__subtitle {
  font-size: 15px;
  margin: 20px 0 10px;
  font-weight: bold;
}

.reserve-confirm__subtitle-left {
  float: left;
  padding-right: 5px;
}

.reserve-confirm__subtitle-right {
  float: left;
  font-size: 13px;
  font-weight: normal;
}

.reserve-confirm__rentalcar-description {
  clear: both;
  padding: 10px 0;
}

.reserve-confirm-right-subtitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.reserve-confirm-right-section {
  margin-bottom: 15px;
}

.reserve-confirm-right-title {
  font-weight: bold;
}

.reserve-confirm-right-content {
  margin-left: 10px;
}

.reserve-confirm-right-content-indented {
  text-indent: -14px;
  margin-left: 24px;
}

.reserve-confirm-right-content-note {
  font-size: 12px;
  color: #959595;
}

.reserve-confirm__child-content,
.reserve-confirm__child-wrapper {
  font-size: 13px;
}

.reserve-confirm__child-wrapper {
  margin-bottom: 20px;
}

.reserve-confirm__input-label {
  margin: 10px 0;
}

.reserve-confirm__child-content {
  margin-left: 10px;
}

.reserve-confirm__wrap {
  select {
    background-color: $white-color;
    margin: 10px 5px 10px 0;
    vertical-align: middle;
    height: 35px;
    border-color: $gray-color;
  }

  .reserve-confirm__project-select {
    margin: 0;
  }
}

.reserve-confirm__peoplenum {
  font-size: 13px;
  margin-top: 10px;
}

.reserve-confirm__shinkansen {
  font-weight: bold;
  font-size: 13px;
  margin-top: 10px;
}

.reserve-confirm__shinkansen-toolate {
  color: $red-color;
}

.reserve-confirm__label {
  font-size: 13px;
  width: 150px;
  line-height: 34px;
}

.reserve-confirm__label-birthday {
  font-size: 13px;
  width: 50px;
  line-height: 34px;
}

.reserve-confirm__input {
  max-width: 300px;
  margin-right: 10px;
}

input.reserve-confirm__input-name:disabled {
  background-color: $gray-color-disabled;
}

input.reserve-confirm__input-name,
input.reserve-confirm__input-birthday {
  width: 120px;
  margin-right: 10px;
}

.reserve-confirm__table {
  margin: $base-margin 0;
}

.reserve-confirm__th,
.reserve-confirm__td {
  border: solid 1px $gray-color;
  padding: 2px 5px;
  font-size: 13px;
  line-height: 22px;
  vertical-align: top;
}

.reserve-confirm__th {
  width: 35%;
  background: $gray-color-light;
  text-align: center;
}

.reserve-confirm__th.table-bottom {
  padding: 10px 12px;
  text-align: left;
}

.reserve-confirm__th.table-bottom .free-list {
  display: inline-block;
  margin: 0 15px 5px 0;
}

.reserve-confirm__th.table-bottom .free-text {
  font-size: 12px;
  font-weight: normal;
}

.reserve-confirm__td {
  width: 70%;
}

.reserve-confirm__table.reserve-confirm__info-table {
  margin-top: 0;
  margin-bottom: 0;
}

.reserve-confirm__table.reserve-confirm__info-table th,
.reserve-confirm__table.reserve-confirm__info-table td {
  font-size: 11px;
  line-height: 13px;
}

.reserve-confirm__info-button {
  margin: 2px 2px 0 auto;
  cursor: pointer;
  @include flex-shrink(0);
}

.reserve-confirm__radio-label {
  display: flex;
  margin-right: 10px;
}

.reserve-confirm__radio-label input {
  display: block;
  margin-top: 4px;
}

.reserve-confirm__errors {
  margin-bottom: $base-margin;
}

.reserve-confirm__show-error,
.reserve-confirm__returning-error {
  padding: $base-margin;
  color: $red-color;
}

.reserve-confirm__back-top {
  margin-top: $base-margin;
  text-align: center;
  text-decoration: underline;
}

.reserve-confirm__small {
  font-size: 11px;
}

.reserve-confirm__small-indented {
  text-indent: -14px;
  margin-left: 14px;
}

.reserve-confirm__total-price {
  font-size: 15px;
  font-weight: bold;
}

.reserve_confirm__signin_modal-body {
  text-align: center;
}

.reserve-confirm__cancel-status {
  font-weight: bold;
  color: $red-color;
}

.reserve-confirm__cancel-detail {
  font-size: 8px;
  margin-left: 10px;
}

.reserve-confirm__travelers {
  margin-bottom: 10px;
}

.reserve-confirm__traveler {
  margin-bottom: 15px;
  margin-left: 10px;
}

.reserve-confirm__rentalcar-wrapper {
  margin-bottom: 15px;
  margin-left: 10px;
}

.reserve-confirm__traveler__peoplenum {
  font-size: 13px;
}

.reserve-confirm__traveler__type {
  margin: 10px 0 5px;
  font-size: 13px;
}

.reserve-confirm__traveler__from-member {
  @include justify-content(space-between);
  margin-left: 10px;

  .reserve-confirm__traveler__label-name,
  .reserve-confirm__traveler__label-birthday {
    line-height: 26px;
  }
}

.reserve-confirm__traveler__input-area {
  display: flex;
  @include flex-wrap(wrap);
  margin-bottom: 5px;
}

.reserve-confirm__traveler__input-area-right {
  line-height: 34px;
  display: flex;
  width: 300px;
}

.reserve-confirm__traveler__name,
.reserve-confirm__traveler__birthday {
  font-size: 13px;
  line-height: 26px;
  margin-right: 10px;
}

.reserve-confirm__traveler__right {
  @include flex-grow(9999);
  text-align: right;
}

.reserve-confirm__traveler__select-button {
  display: inline-block;
  margin-left: auto;

  img,
  span {
    display: block;
    margin: 0 auto;
  }

  span {
    font-size: 10px;
    color: $black-color;
    text-align: center;
  }
}

.reserve-confirm__traveler__label-name {
  font-size: 13px;
  width: 20px;
  line-height: 34px;
}

.reserve-confirm__traveler__label-birthday {
  font-size: 13px;
  width: 50px;
  line-height: 34px;
}

.reserve-confirm__traveler__input {
  width: 150px;
  margin-right: 10px;
  max-width: 300px;
}

.reserve-confirm__traveler__input-birthday {
  width: 110px;
  margin-right: 10px;
}

.reserve-confirm__notification__sub-area {
  margin-top: 15px;
}

.reserve-confirm__notification__cards {
  margin-bottom: 15px;
}

.reserve-confirm__confirm-label {
  font-size: 13px;
  width: 180px;
  line-height: 34px;
}

.reserve-confirm__rentalcar {
  margin: 10px 0 5px;
  font-size: 13px;
}

.reserve-confirm__rentalcar-order-title {
  margin: 10px 0 5px;
}

.reserve-confirm__rentalcar-order-content {
  margin-left: 0;
  padding-left: 20px;
  position: relative;
  border-left: 3px solid #7d7d7d;
}

.reserve-confirm__rentalcar-remove {
  float: right;
  cursor: pointer;
  margin-right: 60px;
}

.reserve-confirm__rentalcar-order-content-confirm {
  margin-left: 0;
  padding-left: 20px;
  position: relative;
  border-left: 3px solid $gray-color-border;
}

.reserve-confirm__traveler__rentalcar_label {
  line-height: 34px;
  display: flex;
  float: left;
}

.reserve-confirm__traveler__rentalcar_input-area {
  line-height: 34px;
}

.reserve-confirm__rentalcar-text {
  margin-left: 73px;
  margin-top: 5px;
}

.reserve-confirm__rentalcar-text-input {
  margin-left: 20px;
  margin-top: 5px;
}

.reserve-confirm__rentalcar-radio {
  margin-left: 20px;
}

.reserve-confirm__rentalcar-select {
  margin-top: 10px;
}

.reserve-confirm__traveler__rentalcar_car-type {
  margin-left: 52px;
}

.reserve-confirm__traveler__rentalcar_other {
  margin-left: 13px;
}

.reserve-confirm__traveler__rentalcar_remarks {
  margin-top: 10px;
  margin-left: 47px;
}

.reserve-confirm__traveler__rentalcar_remarks_confirm {
  margin-left: 45px;
}

.reserve-confirm__add-rentalcar-toggle {
  color: #af985e;
  text-decoration: none;
  transition: color 0.1s linear;
  cursor: pointer;
}

input.ant-calendar-picker-input {
  border: 1px solid #eee;
}

.rentalcar-date-picker .rc-time-picker-input {
  margin-left: 10px;
  padding: 4px !important;
  display: block;
  height: auto;
}

.reserve-confirm__rentalcar-radio p {
  display: inline-block;
}

@media screen and (max-width: 430px) {
  #simple-departuredate-calendar-input {
    margin-left: -115px;
  }

  #simple-returndate-calendar-input {
    margin-left: -115px;
  }

  .rc-time-picker-input {
    margin-left: 5px;
    display: inherit;
    width: 50% !important;
    height: 25px;
  }

  .reserve-confirm__traveler__rentalcar_car-type {
    margin-left: 0;
  }

  .reserve-confirm__rentalcar-order-content-confirm {
    margin-left: 0;
  }

  .reserve-confirm__rentalcar-wrapper {
    margin-bottom: 15px;
  }

  .reserve-confirm__rentalcar-radio .reserve-confirm__input-name {
    margin-left: 20px;
    margin-right: 0;
  }

  .reserve-confirm__rentalcar-radio input {
    float: left;
    display: block;
  }

  .reserve-confirm__rentalcar-radio p {
    margin-top: -7px;
    display: block;
    float: left;
    width: calc(100% - 24px);
  }

  .reserve-confirm__traveler__input-area.reserve-confirm__rentalcar-title-text {
    display: block !important;
  }

  .rentalcar-date-picker {
    margin-left: 70px;
    padding-top: 5px;
  }

  .reserve-confirm__rentalcar-text-input {
    margin-left: 0;
  }

  #car_type_text {
    margin-left: 50px;
  }

  .reserve-confirm__roomnum {
    font-size: 13px;
    margin-top: 10px;
  }

  .reserve-confirm__hotelname {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
}

//
// PC VIEW
//

// .reserve_confirm__pc {
// }

//
// SP VIEW
//

.reserve_confirm__sp {
  .reserve_confirm__outline-wrapper {
    border-bottom: 1px solid $gray-color-border;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
