.#{$prefixClass}-picker {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1000;

  &-hidden {
    display: none;
  }
}
