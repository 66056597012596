.organization-members__invite-area {
  display: flex;
  @include align-items(center);
}

.organization-members__invite-form {
  display: flex;
  @include align-items(center);
}

label.organization-members__invite-label {
  font-weight: normal;
}

div.organization-members__member__filter {
  display: flex;
}

div.organization-members__member__filter-radio {
  width: 25%;
}

label.organization-members__member__filter-label {
  font-weight: normal;
  min-width: 60px;
  margin-top: 5px;
  margin-right: 10px;
  float: left;
}

input.organization-members__member__filter-field {
  float: left;
  width: 75%;
  margin-right: 20px;
}

input.organization-members__member__filter-radio {
  display: none;
}
input.organization-members__member__filter-radio + label {
  padding-left: 20px;
  position: relative;
  float: left;
  margin-right: 10px;
}
input.organization-members__member__filter-radio + label::before {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #999;
  border-radius: 50%;
}
input.organization-members__member__filter-radio:checked + label {
  color: #af985e;
}
input.organization-members__member__filter-radio:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 2px;
  width: 11px;
  height: 11px;
  background: #af985e;
  border-radius: 50%;
}

input.organization-members__invite-field {
  width: 300px;
  margin-left: 10px;
  margin-bottom: 0;
}

.organization-members__invite-select {
  margin-left: 10px;
  margin-bottom: 0;
}

.organization-members__invite-button-wrap {
  margin-left: 10px;
}

.organization-members__invite-errors {
  margin-bottom: 20px;
}

.organization-members__csv-link-area {
  margin-left: 20px;
}

.organization-members__csv-link {
  font-weight: bold;
}

.organization-members__travelers {
  margin-bottom: 10px;
}

.organization-members__traveler {
  margin-bottom: 15px;
  margin-left: 10px;
}

.organization-members__search-label {
  font-weight: normal;
  padding-top: 7px;
  padding-right: 10px;
  float: left;
}

.organization-members__traveler__peoplenum {
  font-size: 13px;
}

.organization-members__traveler__type {
  margin: 10px 0 5px;
  font-size: 13px;
}

.organization-members__traveler__from-member {
  @include justify-content(space-between);
  margin-left: 10px;
}

.member-edit {
  display: flex;
  @include justify-content(space-between);

  &__section {
    width: 48%;
    padding: 5px;
  }

  &__input_area {
    margin-bottom: 10px;
  }

  &__input_label {
    font-size: 13px;
    font-weight: normal;
  }

  &__radio-value {
    width: 80px;

    > span {
      padding-left: 4px;
    }
  }

  &__radio-value-right {
    width: 160px;

    > span {
      padding-left: 4px;
    }
  }

  &__modal-buttons {
    display: flex;
    @include justify-content(flex-end);

    > input,
    > input:hover:hover {
      width: 100px;
      margin-left: 20px;
    }
  }

  &__modal-errors {
    margin: $base-margin;
  }

  &__ekispert-input {
    &__label {
      display: none;
    }
  }
}
