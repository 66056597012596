.trip-report-modal__body {
  &__text {
    text-align: center;
  }

  &__info {
    display: flex;
    @include justify-content(center);

    &__title {
      margin-bottom: 30px;
    }

    dt {
      float: left;
      clear: left;
      margin: 0 0.5em 0 0;
      width: 120px;
    }

    dd {
      float: left;
      margin-left: 1em;
    }
  }

  &__paydate-label {
    width: 120px;
  }

  &__paydate-input {
    width: 120px;
  }

  &__buttons {
    display: flex;
    @include justify-content(center);
    margin: 10px 0;

    input:first-child {
      margin-right: 10px;
    }
  }

  &__form {
    width: 70%;
  }

  &__reject-reason {
    width: 70%;
    margin: 5px 0 10px;
  }
}
