@import '../base/variables';

.Toastify__toast {
  &-body {
    white-space: pre-line;
  }

  &--info {
    background: $accent-color;
  }

  &--error {
    background: $red-color;
  }

  &--success {
    background: $green-color;
  }

  &--warn {
    background: $yellow-color;
  }
}
