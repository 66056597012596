.hotel-sp__wrapper {
  @include calc(min-height, '100vh - #{$header-height} - #{$footer-height} - #{$pc-base-margin} * 2');
  background: white;
  font-weight: bold;
}

.hotel-sp__loading {
  padding: $pc-base-margin 0 0;
  text-align: center;
}

.hotel-sp__top-area {
  display: flex;
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  border-bottom: 1px solid $gray-color-border;
  padding: 12px 15px;

  @media screen and (max-width: $content-width) {
    padding: 12px 0;
  }
}

.hotel-sp__title {
  font-size: 18px;

  @media screen and (max-width: $content-width) {
    font-size: 16px;
  }
}

.hotel-sp__address {
  font-size: 12px;

  @media screen and (max-width: $content-width) {
    font-size: 11px;
  }
}

.hotel-sp__soldout-message {
  @media screen and (max-width: $content-width) {
    font-size: 13px;
  }
}

.hotel-sp__button {
  @include round-button;
  margin-left: 0;

  @media screen and (max-width: $content-width) {
    font-size: 13px;
  }
}

.hotel-sp__body-area {
  padding: 20px;
  width: 100%;

  @media screen and (max-width: $content-width) {
    padding: 15px 0;
  }
}

$hotel-sp__thumb-width: 36px;
$hotel-sp__main-image-width: $hotel-sp__thumb-width * 13;
$hotel-sp__image-area-border-width: 3px;
$hotel-sp__side-margin: 24px;

.hotel-sp__image-area {
  width: 100%;
}

.hotel-sp__main-image {
  width: 100%;
  height: 288px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

$hotel-sp__map-width-normal: 452px;
$hotel-sp__map-width-big: 474px;

.hotel-sp .map {
  width: 100%;
  height: 254px;
}

.hotel-sp__info-area {
  display: flex;
  @include align-items(center);
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  padding: 12px 0;
  background: $gray-color-light;
  font-size: 12px;

  @media screen and (max-width: $content-width) {
    font-size: 11px;
  }
}

.hotel-sp__price-area {
  background: white;
  padding: 5px;
}

.hotel-sp__price {
  font-size: 14px;
  color: $accent-color;

  @media screen and (max-width: $content-width) {
    font-size: 13px;
  }
}

.hotel-sp__price-area .key-value-table {
  font-size: 10px;
  margin-bottom: 5px;
}

.hotel-sp__price-area__soldout {
  margin: 25px;
}

.hotel-sp__amenity-area {
  display: flex;
  @include calc(max-width, '#{$hotel-sp__main-image-width} + #{$hotel-sp__image-area-border-width} * 2');
  width: 100%;
  margin: 15px 0;
}

.hotel-sp__amenities {
  max-width: 223px;
  width: 100%;
}

.hotel-sp__amenities + .hotel-sp__amenities {
  margin-left: auto;
}

.hotel-sp__amenities-title {
  margin-bottom: 0;
}

.hotel-sp__ul {
  list-style-type: disc;
  margin-left: 20px;
}

.hotel-sp__li {
  font-size: 10px;
  font-weight: normal;
  text-indent: -5px;
}

.hotel-sp__readmore {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.hotel-sp__readmore_text {
  color: #af985e;
}

.hotel-sp__toggle-link {
  cursor: pointer;
  font-size: 10px;
}

.hotel-sp__others-area {
  width: 100%;
}

.hotel-sp__detail-box + .hotel-sp__detail-box {
  margin-top: $pc-base-margin;
}

.hotel-sp__detail-box-title {
  margin-bottom: 0;
}

.hotel-sp__detail-small {
  font-size: 10px;
  font-weight: normal;

  b {
    font-size: 12px;
    margin-top: $pc-base-margin;
    display: block;
  }
}
