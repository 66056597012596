.simple-selector-custom {
  display: flex;
  justify-content: start;
  align-items: center;

  &__link {
    margin-right: 10px;
    padding: 10px 5px;
    border: solid 1px #8f7b4a;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    width: 200px;
    border-radius: 7px;
    color: #ab9966;
    background-color: #ffffff;

    &.selected {
      background-color: #8f7b4a;
      color: #ffffff;
    }
  }
}
