.sticky__table__div {
  width: max-content;
}

.sticky-table-container {
  width: 100%;
}

.sticky-table-cell {
  border-bottom: 1px solid #eee;
}

.sticky-table .sticky-table-cell {
  display: table-cell;
  box-sizing: border-box;
  vertical-align: middle;
}

.sticky-table .sticky-table-row {
  display: table-row;
}

.sticky-table .sticky-table-container {
  display: table;
  box-sizing: border-box;
}

.sticky-table {
  position: relative;
  height: calc(100vh - 560px);
  overflow: hidden;
  box-sizing: border-box;
}

.sticky-table .sticky-table-corner {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.sticky-table .sticky-table-header {
  position: absolute;
  z-index: 2;
}

.sticky-table .sticky-table-column,
.sticky-table .sticky-table-header,
.sticky-table .sticky-table-corner {
  background-color: #fff;
  display: block;
}

.sticky-table .sticky-table-column {
  position: absolute;
  z-index: 1;
}

.sticky-table .sticky-table-x-wrapper,
.sticky-table .sticky-table-y-wrapper {
  z-index: 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.sticky-table .sticky-table-x-wrapper {
  min-height: 100%;
  width: 100%;
  overflow-x: auto;
}

.sticky-table .sticky-table-y-wrapper {
  height: 100%;
  overflow-y: auto;
  margin-right: -17px;
  padding-right: 17px;
}

/*
Hack to make table paint on it's own layer.
Because of this it won't paint when the scrollbar paints.
This one was crucial for performance.
Scrolling is probably 10-20x smoother.
*/
.sticky-table .sticky-table-x-wrapper .sticky-table-container {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

/* Scrollbars */
.sticky-table-y-wrapper::-webkit-scrollbar,
.sticky-table-x-wrapper::-webkit-scrollbar {
  display: none;
}

.sticky-table #x-scrollbar,
.sticky-table #y-scrollbar {
  position: absolute;
  background-color: transparent;
  z-index: 5;
  overflow: auto;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

.sticky-table #x-scrollbar {
  left: 0;
  bottom: 0;
  height: 20px;
}

.sticky-table #y-scrollbar {
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
}

.sticky-table #x-scrollbar div {
  height: 100%;
}

.sticky-table #y-scrollbar div {
  width: 100%;
}
