$send-area-height: 82px;
$error-message-height: 63px;
$partner-icon-size: 55px;
$partner-icon-width: 60px;
$user-icon-size: 50px;
$user-icon-width: 60px;

.trip {
  @include responsive(800px);
}

.trip .trip__pc .content-body {
  background: $gray-color-light;
}

.trip__sp .content-body {
  padding: 0;

  .trip__subheader {
    padding: 5px;
  }
}

.modal-container__text02 {
  margin-bottom: 40px;
}

.trip__description {
  padding: $base-margin;
}

.trip__subheader {
  background: $gray-color-light;
  border-bottom: solid 1px $gray-color;
  padding-bottom: 10px;
}

.trip__subheader-return {
  font-size: 13px;
}

.trip__subheader-action {
  margin-left: auto;
}

.trip__subheader-cancel,
.trip__subheader-receipt {
  padding: 2px 6px;
  border: solid 1px $base-color;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  vertical-align: top;
}

.trip__subheader-cancel {
  margin: 10px;
}

.trip__subheader-receipt {
  margin: 10px 20px;
}

.trip__scroll-message {
  font-size: 13px;
  padding: 3px;
  color: $gray-color-text;
  background: $gray-color-light;
  display: none;
}

.trip__flash .flash {
  margin: 0 auto 10px;
  padding: 0;
}

.trip__flash-text {
  padding: 10px;
}

.trip__flash-close {
  margin: auto 0 auto auto;
  padding: 10px;
  cursor: pointer;
}

.trip__send-button-area,
.trip__error-message-area {
  background: $gray-color-light;
  padding: $base-margin;
  position: absolute;
  width: 100vw;
  max-width: $content-width;
}

.trip__error_message-area {
  height: $error-message-height;
}

.trip__send-button-area {
  height: $send-area-height;
}

.trip__send-button-wrap {
  display: flex;
}

input.trip__send-button,
input.trip__send-button:hover,
a.trip__action-button {
  @include button-base;
  width: 50%;
}

a.trip__action-button {
  display: block;
  margin-left: 10px;
  padding: 10px;
  text-align: center;
}

.trip__recipt-modal-close {
  cursor: pointer;
  text-align: center;
  display: block;
  padding-top: 10px;
}

.trip__office-hours {
  font-size: 12px;
  color: $gray-color-text;
  margin-left: 10px;
}

.trip_form_toggle_input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.trip_form_toggle_label {
  width: 44px;
  height: 24px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 23px;
  transition: 0.4s;
  box-sizing: border-box;
  &:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
  }
}

.trip_form_toggle_input:checked + .trip_form_toggle_label {
  background-color: #2563eb;
}

.trip_form_toggle_input:checked + .trip_form_toggle_label:after {
  left: 22px;
}

.trip_form_toggle_button {
  position: relative;
  width: 44px;
  height: 24px;
  margin-right: 10px;
}

.trip_form_toggle {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.trip_form_toggle p {
  font-weight: bold;
  color: #4b5563;
}

.trip_form_item_selected {
  background-color: #8f7b4a !important;
  color: #ffffff !important;
}
