.select {
  font-size: 13px;
  flex-grow: 9999;
  display: flex;
}

.select__sp {
  width: 100%;
}

.select__pc {
  margin: 0 auto;
}
//
// SP
//
.select__search-area {
  background: $gray-color-light;
  padding: 10px 0;
  text-align: center;
  color: $gray-color-text;
  border: 1px solid $gray-color-border;
}

.select__search-link {
  cursor: pointer;
}

.select__hotel-link {
  @include button-base;
  width: 100px;
  padding: 2px 5px;
  font-weight: normal;
}

.select__choose-area {
  padding: 10px 0;
  border-bottom: 1px solid $gray-color-border;
}

.select__choose-area .error {
  padding: 10px;
}

.select__choose-header {
  display: flex;
  padding: 0 10px;
}

.select__title {
  margin-right: auto;
}

.select__loading {
  display: block;
  margin: 0 auto 30px;
}

.select__loading-text {
  text-align: center;
  color: $text-color;
}

.select__transitUrl {
  margin-right: 5px;
}

.select__hide-transit {
  display: block;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}

.select__show-transit {
  display: block;
  text-align: center;
  cursor: pointer;
}

.select__card-wrapper {
  padding: 0 2px;
}

.select__card {
  width: 100%;
  border: 2px solid $accent-color;
  border-radius: 5px;
}

.select__card-wrapper .select__card-not-selected {
  text-align: center;
  padding-top: 70px;
}

.select__card-header {
  background-color: $accent-color;
  text-align: center;
  color: white;
  padding: 0 3px;
}

.select__card-body {
  display: flex;
}

.select__transit-left {
  padding: 10px 10px 10px 13px;
  @include flex-grow(1);
}

.select__transit-right {
  display: flex;
  @include flex-direction(column);
}

$station-area-max-width: 54%;
$select__transit-time: 40px;
$select__transit-bar: 35px;
$select__transit-date: 40px;

@mixin transit-card-text {
  @include calc(max-width, '100% - #{$select__transit-time} - #{$select__transit-bar}');
}

@mixin transit-card-foreign-text {
  @include calc(max-width, '100% - #{$select__transit-time} - #{$select__transit-bar} - #{$select__transit-date}');
}

.select__transit-station {
  display: flex;
  @include align-items(center);
  @include transit-card-text;
  @include flex-shrink(10000);
}

.select__transit-foreign-station {
  display: flex;
  @include align-items(center);
  @include transit-card-foreign-text;
}

.select__transit-time {
  width: 40px;
}

$transit-center-width: 35px;

.select__transit-circle,
.select__transit-small-circle {
  width: $transit-center-width;
  text-align: center;
}

.select__transit-small-circle {
  line-height: 33px;
  font-size: 30px;
  font-weight: bold;
  margin-top: -24px;
}

.select__transit-small {
  font-size: 12px;
  font-weight: normal;
}

.select__transit-segment-bar-area {
  display: flex;
  @include flex-direction(column);
  width: $transit-center-width;
}

$segment-bar-radius: 8px;

@mixin transit-segment-bar {
  margin: 0 auto;
  width: 15px;
  background: $base-color;
  color: $base-color;
}

.select__transit-segment-bar-top {
  @include transit-segment-bar;
  border-top-left-radius: $segment-bar-radius;
  border-top-right-radius: $segment-bar-radius;
  @include flex(1);
}

.select__transit-segment-bar-middle {
  @include transit-segment-bar;
  @include flex(1);
  height: 100%;
}

.select__transit-segment-bar-bottom {
  @include transit-segment-bar;
  border-bottom-left-radius: $segment-bar-radius;
  border-bottom-right-radius: $segment-bar-radius;
  margin-bottom: 4px;
}

.select__transit-name {
  font-weight: bold;
  @include transit-card-text;
}

.select__transit-flight-name {
  font-weight: bold;
  @include transit-card-foreign-text;
}

.select__transit-button,
.select__transit-button-selected {
  display: flex;
  @include flex(1);
  @include align-items(center);
  width: 160px;
  border-left: 1px solid $base-color;
  cursor: pointer;
}

.select__transit-button-selected {
  background: $base-color;
}

$fix-margin: 9px;

.select__transit-mark {
  margin-left: $fix-margin;
}

.select__transit-button-price-wrapper {
  position: relative;
  margin: 0 $fix-margin;
  width: 105px;
  text-align: center;
}

.select__transit-button-no-price-wrapper {
  position: relative;
  width: 100%;
  text-align: center;
}

.select__transit-button-people-text {
  padding: 8px 0 0 3px;
  font-size: 12px;
}

.select__transit-button-selected .select__transit-button-price-wrapper {
  color: white;
}

.select__transit-button-price {
  font-size: 20px;
  font-weight: bold;
}

.select__transit-button-no-seat {
  color: $text-color;
  margin-left: 10px;
}

.select__transit-button-price-desc {
  font-size: 12px;
}

.select__transit-button-ok {
  color: $base-color;
  font-weight: bold;
}

.select__transit-button-selected .select__transit-button-ok {
  color: white;
}

.select__transit-body {
  display: flex;
  @include align-items(center);
}

.select__transit-jr {
  background: url('/images/jr_icon.png') no-repeat 5px 0;
  background-size: contain;
  height: 68px;
}

.select__transit-air {
  background: url('/images/air_icon.png') no-repeat 5px 0;
  background-size: contain;
  height: 68px;
}

.select__transit-gray {
  display: flex;
  color: $gray-color-text;
}

.select__shin-sum-price {
  text-align: right;
  font-weight: bold;
}

.select__hotel-marker,
.select__hotel-marker-selected {
  position: absolute;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 3px 5px;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.select__hotel-marker {
  background: white;
  color: $base-color;
  z-index: 0;
}

.select__hotel-marker-selected {
  background: $accent-color;
  color: white;
  z-index: 2;
}

.select__hotel-popup {
  position: absolute;
  z-index: 3;
}

.select__hotel-card {
  position: relative;
}

$select-hotel-image-width: 129px;

.select__hotel-left {
  padding: 8px 10px;
  @include calc(width, '100% - #{$select-hotel-image-width}');
}

.select__hotel-name {
  font-weight: bold;
}

table.select__hotel-info {
  color: $gray-color-text;
  margin: 0;
}

.select__hotel-info-label,
.select__hotel-info-separator,
.select__hotel-info-value {
  border-bottom: 0;
  padding: 0;
  vertical-align: top;
}

.select__hotel-info-label {
  width: 105px;
}

.select__hotel-info-separator {
  width: 20px;
}

.select__hotel-info-label-small {
  font-size: 12px;
  font-weight: normal;
  color: $gray-color-text;
  margin-top: -4px;
  margin-bottom: 2px;
}

.select__hotel-price-wrapper {
  color: $red-color;
  font-weight: bold;
}

.select__hotel-base-price {
  color: $gray-color-text;
  text-decoration: line-through;
  margin-right: 4px;
}

.select__hotel-over-price {
  font-size: 11px;
}

.select__hotel-image {
  margin-left: auto;
  width: $select-hotel-image-width;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.select__map-button {
  cursor: pointer;
}

.select .map {
  margin-bottom: 10px;
}

.select__next-button-area {
  max-width: $content-width;
  margin: 0 auto;
  padding: 10px;
}

.select__next-button-area .submit-button {
  @include big-button;
}

.select__errors-bottom {
  padding: 0 10px;
  margin-bottom: 10px;
}

.select__no-transit {
  margin-left: 10px;
  color: $gray-color-text;
}

.select__flight-price {
  font-weight: bold;
  margin-bottom: 10px;
}

.select__flight-outword-title {
  margin-left: 10px;
}

.select__flight-homeword-title {
  margin-top: 10px;
  margin-left: 10px;
}

.select__card-total-time {
  font-weight: normal;
}

.select__transit-flight-date {
  width: 40px;
}

table.select__total-price {
  font-weight: bold;
  margin: 10px 10px 0 auto;
  text-align: right;
  width: initial;
}

table.select__total-price td {
  border: none;
  padding: 0;
}

.select__old-price {
  font-weight: normal;
  text-decoration: line-through;
}

.select__hotel-refund {
  margin-left: 6px;
  font-size: 11px;
  background-color: $accent-color;
  border: solid 1px $accent-color;
  border-radius: 5px;
  padding: 2px;
  color: $base-background-color;
}

//
// PC
//

.select-pc .content-body {
  display: flex;
  @include flex-direction(column);

  font-size: 13px;
  padding: 0 20px;
}

.select-pc {
  &__wrapper {
    @include flex-grow(99999);
    display: flex;
    @include flex-direction(column);
    background: white;
    min-height: 0%;
  }

  &__content {
    @include flex-grow(99999);
    display: flex;
    min-height: 0%;
  }
}

$select-pc-left-width: 265px;

.select-pc__left {
  @include flex-basis(360px);
  @include flex-grow(10);
}

.select-pc__left__arrangement-request-button {
  display: flex;
  padding: 10px;

  .tooltip__content {
    right: -60px;
  }
}

.select-pc__map {
  margin-top: $pc-base-margin;
  padding: 10px 5px 5px;
  background: white;

  .map {
    height: 320px;
  }
}

p.select-pc__map-title {
  margin: 0 auto 10px 0;
}

.select-pc__right {
  @include flex-basis(360px);
  @include flex-grow(10);
  display: flex;
  @include flex-direction(column);
}

.select__total-price-description {
  font-size: 10px;
  font-weight: normal;
  margin-left: 5px;
}

.select__tooltip-format {
  font-size: 12px;
}

.select-pc__head {
  font-weight: bold;
}

.select-pc__cards {
  padding: 0 0 15px 15px;
}

.select-pc__card-area {
  width: 215px;
  margin-right: 15px;

  .select__loading {
    margin-top: 30px;
  }
}

.select-pc__hide-button {
  margin-left: auto;
  cursor: pointer;
  color: $accent-color;
}

.select-pc__card,
.select-pc__card-none {
  border: 2px solid $accent-color;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 12px;
}

.select-pc__card-none {
  text-align: center;
  padding: 20px 0;
}

.select-pc__transit-flight-date {
  width: 32px;
  font-size: 11px;
  color: $gray-color-text;
  padding-top: 2px;
}

.select-pc__transit-time {
  width: 31px;
}

.select-pc__transit-bar-wrapper {
  display: flex;
  @include flex-direction(column);
  width: 20px;
}

@mixin select-pc__transit-gray-bar {
  @include flex(1);
  width: 4px;
  margin: 0 auto;
  background: $gray-color-text;
}

.select-pc__transit-gray-top-bar {
  @include select-pc__transit-gray-bar;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.select-pc__transit-gray-middle-bar {
  @include select-pc__transit-gray-bar;
  height: 10px;
}

.select-pc__transit-gray-bottom-bar {
  @include select-pc__transit-gray-bar;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

@mixin select-pc__transit-segment-bar {
  @include flex(1);
  width: 8px;
  margin: 0 auto;
  background: $accent-color;
}

.select-pc__transit-segment-bar-top {
  @include select-pc__transit-segment-bar;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.select-pc__transit-segment-bar-middle {
  @include select-pc__transit-segment-bar;
}

.select-pc__transit-segment-bar-bottom {
  @include select-pc__transit-segment-bar;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.select-pc__transit-name {
  @include flex-shrink(10000);
  color: $accent-color;
  font-weight: bold;
}

.select-pc__card-button-status {
  border: 1px solid $accent-color;
  margin: 10px 10px 10px auto;
  text-align: center;
  padding: 5px;
  font-weight: bold;
  width: 75px;
  display: block;
}

.select-pc__card-button,
.select-pc__card-button-selected {
  border-top: 1px solid $accent-color;
  cursor: pointer;
}

.select-pc__card-button {
  .select-pc__card-button-status {
    color: $accent-color;
  }

  .select-pc__card-button-price {
    color: $gray-color-text;
  }
}

.select-pc__card-button-selected {
  color: $accent-color;

  .select-pc__card-button-status {
    color: white;
    background: $accent-color;
  }
}

.select-pc__card-button-price-area {
  margin: 5px auto 5px 13px;
}

.select-pc__card-button-price-wrapper {
  display: flex;
}

.select-pc__card-button-price {
  font-size: 15px;
  font-weight: bold;
}

.select-pc__transit-button-no-seat {
  color: $text-color;
}

.select-pc__no-results {
  color: $gray-color-text;
  margin: 20px 10px;
}

$select-pc-hotel-image-height: 88px;

.select-pc__hotel-card {
  position: relative;
  padding-top: $select-pc-hotel-image-height;
}

.select-pc__hotel-image {
  margin-left: auto;
  width: 100%;
  height: $select-pc-hotel-image-height;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.select-pc__hotel-name {
  color: white;
  font-weight: bold;
  padding-left: 13px;
  position: absolute;
  bottom: 10px;
}

.select-pc__flight-price {
  font-weight: bold;
  margin-top: 10px;
}

table.select-pc__hotel-info {
  margin-left: 13px;

  td.select__hotel-info-label {
    width: 75px;
  }
}

.select-pc__tabs-area {
  margin-top: 10px;

  .select__loading {
    margin-top: 30px;
  }
}

.select-pc__tabs-title {
  font-weight: bold;
  margin: 0 13px 10px;
}

.select-pc__tabs {
  display: flex;
}

.select-pc__tab {
  @include flex(1);
  font-weight: bold;
  color: $accent-color;
  text-align: center;
  padding: 10px 0;
  background: $accent-color-light;
  border-top: 2px solid $accent-color-light;
  border-bottom: 2px solid $accent-color;
  cursor: pointer;
}

.select-pc__tab-selected {
  border-top: 2px solid $accent-color;
  background: white;
  border-bottom: none;
}

$select-pc-tab-spacer-width: 2px;

.select-pc__tab-spacer {
  width: $select-pc-tab-spacer-width;
  color: $accent-color;
  background: $accent-color;
}

.select-pc__tab-spacer-white {
  width: $select-pc-tab-spacer-width;
  color: white;
  border-bottom: 2px solid $accent-color;
}

.select-pc__tab-content {
  padding: 15px;
}

.select-pc__tab-desc {
  margin-bottom: 10px;
}

.select-pc__transit-list {
  width: 100%;
  table-layout: auto;
  margin: 0;
}

.select-pc__transit-list-head {
  background: $gray-color-light;
  color: $gray-color-text;
  text-align: center;
  border: 1px solid white;
}

.select-pc__transit-list-selected {
  background: $accent-color-light;
}

.select-pc__transit-list-td,
.select-pc__transit-list-td-right {
  font-weight: bold;
}

.select-pc__transit-list-time-wrapper {
  display: flex;
  @include align-items(center);
}

.select-pc__transit-list-time-icon {
  text-align: center;
  margin: 0 10px;
}

.select-pc__transit-list-td-right {
  text-align: right;
  padding-right: 10px;
}

.select-pc__transit-list-company-wrapper {
  display: flex;
  @include align-items(center);
}

.select-pc__transit-company-image {
  display: block;
  width: 44px;
  height: 25px;
  border: 2px solid $gray-color;
  margin-right: 10px;
}

.select-pc__list-select-button {
  @include round-button;
}

.select-pc__list-selected-button {
  @include round-button-hilighted;
}

.select-pc__sort-key-wrapper {
  display: flex;
  @include justify-content(space-between);
}

.select-pc__popup-list-wrapper {
  display: flex;
}

$hotel-sort-key-space: 5px;

.select-pc__sort-key {
  @include calc(width, '(100% - #{$hotel-sort-key-space} * 3) / 4');
  cursor: pointer;
  margin-bottom: 15px;
  padding-bottom: 3px;
  font-size: 10px;
  font-weight: bold;
  border-bottom: 2px solid $gray-color-text;
  color: $gray-color-text;
}

.select-pc__sort-key.active,
.select-pc__sort-key:hover {
  border-bottom: 2px solid $accent-color;
  color: $accent-color;
}

.select-pc__flight-small,
.select-pc__shinkansen-small {
  font-size: 8px;
  padding-bottom: 10px;
}
