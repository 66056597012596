table:not([class^='Mui']) {
  @include font-feature-settings('kern', 'liga', 'tnum');
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid darken($base-border-color, 15%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
  word-break: break-all;
}

td.no-border-bottom {
  border-bottom: none;
}

td.border-top {
  border-top: 1px solid darken($base-border-color, 15%);
}
