.user-edit__title {
  margin: $base-margin $base-margin 3px;
}

.user-edit__section {
  display: flex;
  border-bottom: 1px solid $base-color;
  padding: $base-margin;
}

.user-edit__section-recoverable {
  border-bottom: 0;
}

.user-edit__section.radio-box input:checked + span {
  background-color: $base-color;
  color: #fff;
}

@mixin user-edit-label {
  color: $gray-color-text;
  font-weight: bold;
}

$label-width: 120px;

.user-edit__left-label {
  @include user-edit-label;
  width: $label-width;
}

.user-edit__sub-label {
  width: 20px;
}

.user-edit__pass-confirm {
  margin-top: -12px;
}

@mixin user-edit__input {
  @include placeholder {
    color: $base-color;
  }

  border: 0;
  padding: 0;
  margin-bottom: 0;
  box-shadow: none;
  line-height: 15px;
  height: 20px;
  background: white;
}

input.user-edit__input {
  @include user-edit__input;
  @include calc(width, '100% - #{$label-width}');
}

input.user-edit__input-split {
  @include user-edit__input;
  @include calc(width, '45% - (#{$label-width} / 2)');
  margin-right: 10px;
}

select.user-edit__input {
  @include user-edit__input;
  @include appearance(none);
}

.user-edit__section select {
  margin: 0 5px 0 0;
}

.user-edit__button {
  max-width: $content-width;
  margin: 0 auto;
  padding: 0 $base-margin $base-margin;
}

.user-edit__button input {
  @include big-button;
}

.user-edit__section-mileage {
  border-bottom: 1px solid $base-color;
  padding: $base-margin;
}

.user-edit__section__mileage-items {
  display: flex;
  flex-direction: column;
}

.user-edit__section__mileage-items__item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.user-edit__section__mileage-items__item select {
  margin-bottom: 0.75em;
}

.user-edit__section__mileage-items__remove {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 0 0 0.75rem 8px;
}

.user-edit__section__mileage-items__add {
  text-align: right;
  color: $base-color;
}

.user-edit__registration-link {
  display: block;
  color: $base-color;
  text-align: center;
  cursor: pointer;
  margin: $base-margin 0;
}

.user-edit__modal-errors {
  margin: $base-margin;
}

.user-edit__modal-cancel {
  display: block;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}

.user-edit__errors,
.user-edit__notice {
  margin: 0 $base-margin $base-margin;
}

.user-edit__loading {
  display: block;
  margin: 0 auto;
}
